import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Heading } from 'components/Heading';
import Swal from 'sweetalert2';
import axios from 'axios';

const validationSchema = Yup.object().shape({
  EMAIL: Yup.string().email('Invalid email').required('Required'),
  CARD_NUMBER: Yup.string().matches(/^999999999999$/, 'Invalid Card number').required('Required'),
  NAME: Yup.string().required('Required'),
  AMOUNT: Yup.number().required('Required').label("Amount"),
  EXPIRY: Yup.string().matches(/^12\/30$/, 'Invalid EXPIRY date').required('Required'),
  CVV: Yup.string().matches(/^123$/, 'Invalid CVV number').required('Required'),
});


const PaymentPage = () => {

  let demoData: any = '';
  let formData: any = '';

  try {
    // console.log("checking");
    demoData = JSON.parse(localStorage.getItem('demo_data')) || "";
    formData = JSON.parse(localStorage.getItem('form_data')) || "";
  } catch (err) {
    console.log("err ", err);
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(!formData?.MOBILE_NUMBER) {
  //       window.location.href = "/"
  //     }
  //   },1000);
  // },[formData?.MOBILE_NUMBER]);

  return /* !!formData?.MOBILE_NUMBER ? */ <div className='py-4 space-y-6'>
    <div className="text-center">
      <Heading size="4xl" as="h6" className="!text-black-900">
        Demo Payment Page
      </Heading>
    </div>
    <div className="bg-gray-100 p-8 rounded-lg max-w-md mx-auto">
      <Formik
        initialValues={{
          EMAIL: '',
          INSTITUTE_NAME: formData?.INSTITUTE_NAME || "",
          PHONE: formData?.MOBILE_NUMBER || '',
          CARD_NUMBER: '999999999999',
          NAME: formData?.NAME || '',
          EXPIRY: '12/30',
          CVV: '123',
          PAYMENT_STATUS: 'SUCCESS',
          AMOUNT: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const campaginDetailResponse = await axios.post(`https://stg.soomuch.ai/api/v1/payment/demoPay`, values);
            if(campaginDetailResponse?.data?.message === "Payment Successful." || values?.PAYMENT_STATUS === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: campaginDetailResponse?.data?.message || "Payment Successful",
                showConfirmButton: false,
                timer: 3000
              });
            } else {
              Swal.fire({
                icon: "error",
                title: campaginDetailResponse?.data?.message || "Payment Failed",
                showConfirmButton: false,
                timer: 3000
              });
            }
          } catch (err) {
            Swal.fire({
              icon: "error",
              title: err?.message || "Payment Failed",
              showConfirmButton: false,
              timer: 3000
            });
          }
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="mb-4">
              <label htmlFor="EMAIL" className="bg-A700_7f block text-sm font-medium text-gray-700">Email <span className='text-[red]'>*</span></label>
              <Field
                style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                type="email"
                id="EMAIL"
                name="EMAIL"
                className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.EMAIL && errors.EMAIL ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Email Address"
              />
              <ErrorMessage name="EMAIL" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-4">
              <label htmlFor="CARD_NUMBER" className="bg-A700_7f block text-sm font-medium text-gray-700">Card Number <span className='text-[red]'>*</span></label>
              <Field
                style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                type="text"
                id="CARD_NUMBER"
                name="CARD_NUMBER"
                className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.CARD_NUMBER && errors.CARD_NUMBER ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="1234 5678 9012 3456"
              />
              <ErrorMessage name="CARD_NUMBER" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="mb-4">
              <label htmlFor="NAME" className="block text-sm font-medium text-gray-700">Name on Card <span className='text-[red]'>*</span></label>
              <Field
                style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                type="text"
                id="NAME"
                name="NAME"
                className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.NAME && errors.NAME ? 'border-red-500' : 'border-gray-300'}`}
                placeholder="Name"
              />
              <ErrorMessage name="NAME" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            <div className="flex justify-between">
              <div className="mb-4 w-1/2 mr-2">
                <label htmlFor="EXPIRY" className="block text-sm font-medium text-gray-700">Expiry Date <span className='text-[red]'>*</span></label>
                <Field
                  style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                  type="text"
                  id="EXPIRY"
                  name="EXPIRY"
                  className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.EXPIRY && errors.EXPIRY ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="MM/YY"
                />
                <ErrorMessage name="EXPIRY" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4 w-1/2 ml-2">
                <label htmlFor="CVV" className="block text-sm font-medium text-gray-700">CVV <span className='text-[red]'>*</span></label>
                <Field
                  style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                  type="text"
                  id="CVV"
                  name="CVV"
                  className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.CVV && errors.CVV ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder="CVV"
                />
                <ErrorMessage name="CVV" component="div" className="text-red-500 text-sm mt-1" />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="mb-4 w-1/2 mr-2">
                <label htmlFor="AMOUNT" className="block text-sm font-medium text-gray-700">Amount <span className='text-[red]'>*</span></label>
                <Field
                  style={{ backgroundColor: "white", borderRadius: "5px", padding: "0px 6px" }}
                  type="text"
                  id="AMOUNT"
                  name="AMOUNT"
                  className={`mt-1 h-[44px] w-full rounded-lg shadow-sm ${touched.AMOUNT && errors.AMOUNT ? 'border-red-500' : 'border-gray-300'}`}
                  placeholder=""
                />
                <ErrorMessage name="AMOUNT" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div>
                <label className="block text-gray-700">Payment Status :</label>
                <div className="my-2 flex justify-between items-center gap-4">
                  {['SUCCESS', 'FAIL'].map(PAYMENT_STATUS => (
                    <label key={PAYMENT_STATUS} className="inline-flex items-center">
                      <Field
                        type="radio"
                        name="PAYMENT_STATUS"
                        value={PAYMENT_STATUS}
                        className="form-radio"
                      />
                      <span className="ml-2">{PAYMENT_STATUS}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <button type="submit" className="mt-2 w-full bg-blue-600 text-[white] p-2 font-semibold rounded-md hover:bg-blue-700">Pay</button>
          </Form>
        )}
      </Formik>
    </div>
  </div> /* : <></> */
};

export default PaymentPage;
