import React from "react";

const sizes = {
  "3xl": "text-[21px] font-extrabold",
  "2xl": "text-xl font-semibold",
  "5xl": "text-[25px] font-semibold md:text-[23px] sm:text-[21px]",
  "4xl": "text-[22px] font-semibold",
  "7xl": "text-[32px] font-semibold md:text-3xl sm:text-[28px]",
  "6xl": "text-[27px] font-semibold md:text-[25px] sm:text-[23px]",
  "9xl": "text-[40px] font-black md:text-[38px] sm:text-4xl",
  "8xl": "text-[35px] font-black md:text-[33px] sm:text-[31px]",
  xl: "text-lg font-bold",
  s: "text-[15px] font-semibold",
  md: "text-base font-bold",
  xs: "text-[13px] font-extrabold",
  lg: "text-[17px] font-bold",
  "11xl": "text-5xl font-black md:text-[44px] sm:text-[38px]",
  "10xl": "text-[45px] font-black md:text-[41px] sm:text-[35px]",
};

export type HeadingProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  children,
  className = "",
  size = "3xl",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component className={`text-white-A700_01 font-rethinksans ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
