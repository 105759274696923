import React, { useEffect } from "react";
import * as ModalR from 'react-modal';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps, ErrorMessage } from 'formik';
import { Button } from "components/Button";

const InboundModal = ({
    showModal, customStyles, setshowModal
}) => {

    return (
        <ModalR
            isOpen={showModal?.display}
            contentLabel="Example Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '90%',
                    width: '30rem',
                    minHeight: '6rem',
                    borderRadius: '1em',
                    boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
                    padding: '0px',
                    border: '0px'
                },
                overlay: {
                    background: 'rgba(0, 0, 0, 0.4)',
                    zIndex: '999',
                },
            }}
        >
            <div className="flex justify-between items-center bg-[#8933F9] py-2">
                <div className="w-10"></div>
                <h1 className="font-bold text-lg text-[white]">INBOUND SCREEN</h1>
                <div onClick={() => setshowModal({ display: false, ID: 0 })} className="cursor-pointer bg-[white] rounded-lg p-1 mr-1">
                    <img src="/images/Close.svg" alt="close" className="h-6" />
                </div>
            </div>
            <div className="px-8 py-2 overflow-y-auto max-h-[500px] space-y-4">
                <div className="flex justify-center mt-2">
                    <p className="font-bold">WELCOME TO SOOMUCH.AI DEMO SCHOOL</p>
                </div>
                <div className="flex justify-between items-center gap-8">
                    <div>
                        <p className="font-semibold">
                            <span className="text-[red]">8885035035</span> IS YOUR DEDICATED INBOUND NUMBER.
                            TO KNOW YOUR CHILD INFORMATION <span className="text-[green]">24 x 7</span>.
                            IN THE LANGUAGE OF YOUR CHOICE.
                        </p>
                    </div>
                    <div className="">
                    <img src="images/inbound_img.svg" alt="" className="w-[500px]" />
                    </div>
                </div>
            </div>
        </ModalR>
    );
}

export default InboundModal;