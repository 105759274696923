import { Helmet } from "react-helmet";
import { Heading, Img, Button, Text } from "../../components";
import Faq from "../../components/Faq";
import CommunicationSection from "../../components/CommunicationSection";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom';
import HeroTitle from "components/HeroTitle";
import FaqSection from "components/FaqSection";
import { Link as AnchorTag } from 'react-scroll';
import InboundButton from "components/InboundButton";

const data = [
    { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
    { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
    { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
    { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
    { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

const data1 = [
    { appsImage: "images/3_mobile_app.svg", appsDescription: "3 Mobile Apps" },
    { appsImage: "images/5_min_mastery.svg", appsDescription: "5-Minute Mastery" },
    { appsImage: "images/1_free_customization.svg", appsDescription: "1 Free Customization" },
    { appsImage: "images/inbuild_virtual_assistance.svg", appsDescription: "INBUILT Virtual Assistants" },
];

const mobileSection = [
    { appsImage: "images/mobile_super_admin.svg", appsDescription: "Super Admin App" },
    { appsImage: "images/parent_app.svg", appsDescription: "Parent App" },
    { appsImage: "images/faculty_app.svg", appsDescription: "Faculty App" },
];

const unlockSoomuchAISection = [
    { appsImage: "images/rapid_learning.svg", appsDescription: "Rapid Learning" },
    { appsImage: "images/effortless_operations.svg", appsDescription: "Effortless Operation" },
    { appsImage: "images/intelligent_search.svg", appsDescription: "Intelligent Search" },
];

const tailoredSection = [
    { appsImage: "images/flexiblity.svg", appsDescription: "Flexibility" },
    { appsImage: "images/complementry_customization.svg", appsDescription: "Complimentary Customization" },
    { appsImage: "images/seamless_fit.svg", appsDescription: "Seamless Fit" },
];

const revolutionizingSection = [
    { appsImage: "images/instance_assistance.svg", appsDescription: "Instant Assistance" },
    { appsImage: "images/effeciency_unleashed.svg", appsDescription: "Efficiency Unleashed" },
    { appsImage: "images/accessable_in_30_sec.svg", appsDescription: "Accessible in 30 Seconds" },
    { appsImage: "images/seamless_user_experience.svg", appsDescription: "Seamless User Experience" },
];

const EffectiveCommunication = ({ handleModal }) => {

    const [id, setid] = React.useState<number>(0);

    let demoData: any = '';

    try {
        console.log("checking");
        demoData = JSON.parse(localStorage.getItem('demo_data')) || "";
    } catch (err) {
        console.log("err ", err);
    }

    React.useEffect(() => {
        setTimeout(() => {
            setid(id => (id + 1))
        }, 5000)
    }, [id]);

    return (
        <div className="flex flex-col gap-8">
            {/* navigation bar section */}

            {/* hero section */}
            <div className="flex flex-col gap-[50px]">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 md:items-end">
                        <Button
                            size="xs"
                            variant="fill"
                            className="min-w-[250px] hidden md:block rounded-bl-[10px] rounded-tl-[10px] font-extrabold sm:px-5"
                        >
                            Patent Filed
                        </Button>
                    </div>

                    <div className="flex flex-row relative">
                        <div className="container-xs md:p-5 md:px-5">
                            <div className="flex flex-col items-center gap-1 md:text-center">
                                <Heading size="8xl" as="h1" className="!font-poppins !text-black-900_b2">
                                    India’s First Business Ecosystem for{" "}
                                </Heading>
                                <HeroTitle />
                            </div>
                        </div>
                        <Button
                            size="xs"
                            variant="fill"
                            className="absolute top-2 right-0 min-w-[250px] rounded-bl-[10px] rounded-tl-[10px] font-extrabold md:hidden"
                        >
                            Patent Filed
                        </Button>
                    </div>
                    <div className="relative self-stretch">
                        <div className="flex w-full flex-col">
                            <div className="relative z-[1] flex flex-col items-center">
                                <div className="grid grid-cols-5 px-2 h-[9.3vh] md:h-[7.3vh] gap-12 sm:h-auto sm:gap-2 sm:px-4">
                                    <Link to={"/"}>
                                        <img src="/images/img_a1.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/digital-marketing"}>
                                        <img src="/images/img_a2.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/customer-satisfaction"}>
                                        <img src="/images/img_a3.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/fee-collection-process"}>
                                        <img src="/images/img_a4.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/ai-powered-managment-hub"}>
                                        <img src="/images/img_a5.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                </div>
                                <div className="absolute container-xs top-[85px] left-[35%] sm:hidden">
                                    <Img src="images/next-arrow.gif" alt="arrow image" className="h-[30px] rotate-45" />
                                </div>
                                <div className="container-xs relative z-[-1] pl-[130px] mt-[8px] sm:pl-[50px] sm:m-0 md:pl-[70px]">
                                    <Img src="images/img_arrow_1.svg" alt="arrow image" className="h-[130px] sm:h-[100px] md:h-[150px]" />
                                </div>
                            </div>
                            <div className="container-xs relative pl-[35px] pr-9 md:p-5 sm:px-5 sm:py-0">
                                <div className="flex items-center justify-between items-stretch gap-5 md:flex-col">
                                    <div className=" py-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[39%] flex-col items-center gap-3 rounded-tl-[52px] rounded-br-[52px] border border-solid bg-gradient5  md:w-full sm:p-5">
                                        <Heading
                                            size="s"
                                            as="h3"
                                            className="w-[95%] text-center !font-semibold leading-[22px] !text-black-900 md:w-full"
                                        >
                                            Automated Streamline communication, cut administrative costs by 15%-yielding a minimum of 1 to
                                            2 lakhs.
                                        </Heading>
                                        <div className="flex w-[92%] justify-between gap-5 md:w-full sm:flex-col sm:items-center">
                                            <Button
                                                // disabled={demoData?.SOURCE === "COMM" || demoData?.SOURCE_ARRAY?.includes("COMM")}
                                                color="white_A700_01"
                                                size="xl"
                                                variant="fill"
                                                shape="round"
                                                className="min-w-[160px] font-medium"
                                                onClick={handleModal}
                                            >
                                                {!!demoData?.DEMO_CAMPAIGN_ID ? "Live Demo" : "Book a LIVE Demo"}
                                            </Button>
                                            <AnchorTag
                                                activeClass="active"
                                                to={"AUTO_CONNECT"}
                                                spy={true}
                                                smooth={true}
                                                offset={-120}
                                                duration={1000}
                                            >
                                                <Button
                                                    size="xl"
                                                    variant="fill"
                                                    shape="round"
                                                    className="min-w-[160px] font-medium shadow-sm sm:px-5"
                                                >
                                                    Know More
                                                </Button>
                                            </AnchorTag>
                                        </div>
                                    </div>
                                    <div className="pb-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[35%] justify-center rounded-tl-[52px] rounded-br-[52px] bg-gradient5 md:w-full">
                                        <div className="flex w-full flex-col items-center gap-[9px] px-6">
                                            <Heading
                                                size="s"
                                                as="h4"
                                                className="flex items-center justify-center rounded-bl-[10px] rounded-br-[10px] bg-purple-900 px-[22px] py-[3px] !font-reemkufi sm:px-5"
                                            >
                                                POTENTIAL EARNINGS
                                            </Heading>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Earning minimum:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>1,50,000
                                                </Text>
                                            </div>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Total Earnings:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>1,50,000
                                                </Text>
                                            </div>
                                            <Heading size="md" as="h6" className="!font-semibold !text-red-A700_99">
                                                *Above data is subject to Terms and Conditions.
                                            </Heading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 right-0 top-[37%] z-[2] m-auto flex w-[35%] items-center justify-center gap-2 rounded-md border border-solid border-black-900 bg-gray-100_02 sm:relative sm:hidden md:top-[15%] md:w-[60%]">
                            <Button
                                size="md"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[29%] rounded-bl-md rounded-br-2xl rounded-tl-md rounded-tr-2xl !bg-gradient font-poppins font-bold"
                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="w-[71%] text-center leading-[23px] !text-black-900 sm:w-full sm:p-5">
                                <>
                                    To gain 2-5 lakhs with DIGITAL MARKETING <br />
                                    by reaching upto 1,00,000 families.
                                </>
                            </Text>
                        </div>
                        <div className="absolute left-[10%] right-0 top-[8%] z-[2] m-auto hidden sm:flex items-center justify-center rounded-md border border-solid border-black-900 bg-gray-100_02 sm:w-[80%]">
                            <Button
                                size="xl"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[20%] sm:p-0 rounded-bl-md rounded-br-xl rounded-tl-md rounded-tr-xl !bg-gradient font-poppins font-bold sm:h-[40px] sm:text-xs"                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="text-center leading-[18px] !text-black-900 sm:w-full px-1 sm:text-xs">
                                <>
                                    To gain 2-5 lakhs with DIGITAL MARKETING
                                    by reaching upto 1,00,000 families.
                                </>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xs my-2">
                <Img
                    src="images/img_mask_group.png"
                    alt="masked image"
                    className="h-[10px] w-full rounded-md object-cover"
                />
            </div>

            {/* about section */}
            <AboutSection />
            <div className="container-xs flex flex-col gap-[50px] sm:p-[20px]">

                {/* video section */}
                <div className="rounded-[40px] border-deep_purple-100 bg-deep_purple-700_01 pr-[13px] sm:p-4">
                    <div className="flex items-center gap-5 md:flex-col">
                        <div className="relative h-[500px] flex flex-1 items-center justify-between gap-5 rounded-[40px] border-[3px] border-solid border-deep_purple-100 bg-deep_purple-100 shadow-2xl sm:justify-center">
                            {/* <Heading as="h6" className="mb-[26px] self-end !text-[21.94px] !text-deep_purple-700">
                                VIDEO GOES HERE
                            </Heading> */}
                            <video width={"100%"} height={"500px"} /* autoPlay muted loop */ controls className="object-cover border border-solid sm:w-[80%]">
                                <source src="https://bhashinistaticassets.blob.core.windows.net/bhashini-assets/bhashini/videos/bhashini-video.mp4" type="video/mp4" />
                            </video>
                            <div className="absolute top-[40%] right-[-50px] sm:hidden">
                                <Button size="5xl" shape="square" className="w-[100px]">
                                    <Img src="images/img_ellipse_557.svg" />
                                </Button>
                            </div>
                        </div>
                        <div className="flex w-[32%] flex-col justify-between items-center md:w-full min-h-[400px]">
                            <Heading size="6xl" as="h2" className="!font-lexend">
                                Government Initiative
                            </Heading>
                            <Heading size="s" as="p" className="mt-[33px] w-[97%] text-right !font-lexend leading-5 md:w-full sm:text-center">
                                <>
                                    Inspired by Prime Minister initiative “multilingual communication platform called “BHASINI”. This
                                    initiative emphasizes the significance of communicating in the <br />
                                    preferred language of the customer for <br />
                                    better satisfaction and experience.
                                </>
                            </Heading>
                            <Heading size="2xl" as="h5" className="mt-[39px] !font-lexend sm:text-center">
                                Please watch this video to learn more.
                            </Heading>
                            <div className="flex justify-end items-end gap-4 sm:items-center sm:mt-[20px]" >
                                <div className="text-right">
                                    <Heading
                                        size="md"
                                        as="h6"
                                        className="ml-[41px] mt-[83px] self-start !font-lexend !font-semibold md:ml-0 sm:m-0"
                                    >
                                        Inspired by Prime Minister{" "}
                                    </Heading>
                                    <Heading size="s" as="p" className="!font-lexend">
                                        Shri Narendra Modi
                                    </Heading>
                                </div>
                                <img src="images/modi.png" alt="pm_modi" className="h-[110px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* features section */}
            <div id={"AUTO_CONNECT"} className="mt-[50px] flex flex-col bg-gray-100_01 pb-[50px] pt-[45px] md:py-5">
                <div className="container-xs flex flex-col items-center gap-[47px] pl-[37px] pr-[34px] md:p-5 sm:px-5">
                    <div className="flex flex-col items-start gap-10 self-stretch">
                        <div className="flex w-[95%] items-center gap-[41px] md:w-full md:flex-col sm:flex-col-reverse">
                            <Img
                                src="images/img_group_151x150.png"
                                alt="logo image"
                                className="h-[151px] w-[150px] object-cover md:w-full md:object-contain	sm:m-auto"
                            />
                            <Heading
                                size="8xl"
                                as="h2"
                                className="w-[86%] bg-gradient2 bg-clip-text text-center !font-lexenddeca leading-[50px] !text-transparent md:w-full sm:leading-[40px]"
                            >
                                <span className="bg-gradient2 bg-clip-text text-transparent">
                                    Transform Your School&#39;s Communication with&nbsp;
                                </span>
                                <span className="bg-gradient2 bg-clip-text text-[45px] text-transparent sm:text-[38px]">AUTO CONNECT</span>
                            </Heading>
                        </div>
                        <Heading
                            size="9xl"
                            as="h3"
                            className="ml-3 bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent md:ml-0"
                        >
                            <span className="bg-gradient2 bg-clip-text text-transparent">Why Choose&nbsp;</span>
                            <span className="bg-gradient2 bg-clip-text text-[43px] text-transparent sm:text-[38px]">AUTO CONNECT ?</span>
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="ml-[27px] w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-gray-900_01 md:ml-0"
                        >
                            <>
                                Experience seamless communication with AUTO CONNECT, catering to prospective admissions and current
                                parents in their preferred language, spanning over 10 different languages. Enhancing comfort,
                                comprehension, and satisfaction for all parties involved, it includes a dedicated 24/7 phone <br />
                                line for convenience.
                            </>
                        </Heading>
                    </div>
                    <div className="flex w-[92%] flex-col items-center justify-center gap-11 rounded-[40px] bg-deep_purple-100 px-14 pb-[166px] pt-[139px] md:w-full md:p-5">
                        <Button size="5xl" shape="square" className="w-[100px]">
                            <Img src="images/img_ellipse_557.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.94px] !text-deep_purple-700">
                            VIDEO COMING SOON
                        </Heading>
                    </div>
                    <InboundButton />
                    {/* <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Ready to Transform Your Communication?
                        </Heading>
                        <Button
                            size="lg"
                            shape="round"
                            color="deep_purple_400_red_500"
                            className="min-w-[392px] font-extrabold sm:px-5"
                            onClick={handleModal}
                        >
                            Inbound Comprehensive Communication
                        </Button>
                    </div> */}
                </div>
            </div>

            {/* benefits section */}
            <div className="laptop:container-xs relative grid grid-cols-2 justify-center gap-[9px] px-[25px] md:grid-cols-1 sm:px-5">
                <div className="flex w-full flex-col items-center justify-center rounded-full bg-gradient3 border-[10px] border-[white] hidden sm:block text-center">
                    <Heading size="8xl" as="h6" className="!font-semibold">
                        FEATURES
                    </Heading>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#552AA1] p-8 hover:shadow-[15px_35px_60px_2px_#552AA1] sm:h-[420px]">
                    <div className="flex w-full flex-col items-start gap-4">
                        <Button color="purple_A200_01" size="2xl" variant="fill" shape="round" className="w-[61px] !rounded-lg">
                            <Img src="images/img_close.svg" />
                        </Button>
                        <Heading as="h2" className="!text-[21.94px]">
                            Dedicated Phone Number with 24/7 Support
                        </Heading>
                        <Heading size="s" as="h3" className="w-full !text-[15.75px] leading-6">
                            <>
                                Parents can call anytime to get information on attendance, fees, <br />
                                exam timetable, exam results, feedback, and other general <br />
                                notifications in their preferred language. Plus, a personalized <br />
                                welcome note featuring your school&#39;s name.
                            </>
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#B41AB1] p-8 hover:shadow-[15px_35px_60px_2px_#B41AB1] sm:text-right">
                    <div className="flex w-full flex-col items-end gap-4">
                        <Button
                            color="purple_A200_02"
                            size="4xl"
                            variant="fill"
                            shape="round"
                            className="w-[61px] !rounded-lg md:mr-0"
                        >
                            <Img src="images/img_close_black_900.svg" />
                        </Button>
                        <Heading as="h4" className="!font-inter !text-[21.94px] !font-bold">
                            100% Delivery Guarantee
                        </Heading>
                        <Heading size="s" as="h5" className="w-full text-right !text-[15.75px] leading-6">
                            With AUTO CONNECT, you can be confident that every message reaches its destination.
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-lg rounded-2xl bg-[#B41AB1] p-8 hover:shadow-[15px_35px_60px_2px_#B41AB1] sm:h-[420px]">
                    <div className="flex w-full flex-col items-start gap-4">
                        <Button color="purple_A200_02" size="4xl" variant="fill" shape="round" className="w-[64px] !rounded-lg">
                            <Img src="images/img_6594276b9d95556.svg" />
                        </Button>
                        <Heading size="4xl" as="h5" className="!text-[22.13px] !font-bold">
                            Multiple Retry Attempts
                        </Heading>
                        <Heading size="s" as="p" className="w-full !text-[15.63px] leading-6">
                            <>
                                Don&#39;t worry about missing any messages. With AUTO CONNECT, if parents
                                <br />
                                don&#39;t pick up the call, it tries again automatically. If parents see a missed <br />
                                call and call back the dedicated number, they&#39;ll hear the missed <br />
                                message first, followed by other options.{" "}
                            </>
                        </Heading>
                    </div>
                </div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:hidden">
                    <div className="flex w-full flex-col items-center justify-center rounded-full bg-gradient3 py-[70px] px-[10px] border-[10px] border-[white] hover:shadow-[15px_35px_60px_2px_#552AA1]">
                        <Heading size="8xl" as="h6" className="!font-semibold">
                            FEATURES
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#552AA1] p-8 hover:shadow-[15px_35px_60px_2px_#552AA1]">
                    <div className="flex w-full flex-col items-end gap-4">
                        <Button color="purple_A200_01" size="2xl" variant="fill" shape="round" className="w-[59px] !rounded-lg">
                            <Img src="images/img_television.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.56px] !font-bold md:mr-0">
                            Multiple Language Options
                        </Heading>
                        <Heading size="s" as="p" className="w-full text-right !font-inter !text-[15.63px] leading-6">
                            <>
                                Communicate with parents in their preferred language, whether it&#39;s <br />
                                Hindi, Telugu, English, or up to 10 different languages
                            </>
                        </Heading>
                    </div>
                </div>
            </div>

            <div className="laptop:container-xs flex justify-evenly gap-2 w-full pb-[50px] pt-[45px] px-[25px] sm:block">
                <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12 sm:w-[100%]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Streamlined
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                        <img src="images/social_success_top_right_3.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                        <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                            <div className="social_success_text_show_none_SOOMUCH space-y-2">
                                <p className="text-md text-[white] font-normal !font-poppins">One-Time Setup</p>
                                <p className="text-sm text-[white] font-light text-gray-300">
                                    Our dedicated virtual assistants will help you set everything up. Once it's done, the process is entirely automated.
                                </p>
                            </div>
                            <Heading size="5xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                One-Time Setup
                            </Heading>
                        </div>
                    </div>
                    {/* <div className="max-w-[60%] h-[270px] rounded-lg bg-deep_purple-700 p-4">
                        <div className="h-[99%] px-[50px] border border-solid rounded-lg flex justify-center items-center">
                            <Heading size="5xl" as="h4" className="!font-poppins">
                                One-Time Setup
                            </Heading>
                        </div>
                    </div> */}
                    <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                        <img src="images/social_success_top_right_2.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                        <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                            <div className="social_success_text_show_none_SOOMUCH space-y-2">
                                <p className="text-md text-[white] font-normal !font-poppins">Inspired by Telecom and <br /> Banking Sectors</p>
                                <p className="text-sm text-[white] font-light text-gray-300">
                                    We use the same reliable and efficient communication methods as leading telecom <br /> and banking industries.
                                </p>
                            </div>
                            <Heading size="5xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                Inspired by Telecom and Banking Sectors
                            </Heading>
                        </div>
                    </div>

                    {/* <div className="max-w-[60%] h-[270px] rounded-lg bg-deep_purple-700 p-4">
                        <div className="h-[99%] px-[50px] border border-solid rounded-lg flex justify-center items-center text-center">
                            <Heading size="2xl" as="h4" className="!font-poppins">
                                Inspired by Telecom and Banking Sectors
                            </Heading>
                        </div>
                    </div> */}

                </div>
                <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12 sm:w-[100%] sm:mt-4">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Benefits
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                        <img src="images/social_success_top_right_4.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                        <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                            <div className="social_success_text_show_none_SOOMUCH space-y-2">
                                <p className="text-md text-[white] font-normal !font-poppins">Ensures Inclusive <br /> Communication</p>
                                <p className="text-sm text-[white] font-light text-gray-300">
                                    By communicating in the parents preferred language, you ensure every parent stays <br /> informed and satisfied.
                                </p>
                            </div>
                            <Heading size="5xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                Ensures Inclusive Communication
                            </Heading>
                        </div>
                    </div>
                    {/* <div className="max-w-[60%] h-[270px] rounded-lg bg-deep_purple-700 p-4">
                        <div className="h-[99%] px-[50px] border border-solid rounded-lg flex justify-center items-center text-center">
                            <Heading size="2xl" as="h4" className="!font-poppins">
                                Ensures Inclusive Communication
                            </Heading>
                        </div>
                    </div> */}

                    <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                        <img src="images/social_success_top_right_1.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                        <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                            <div className="social_success_text_show_none_SOOMUCH space-y-2">
                                <p className="text-md text-[white] font-normal !font-poppins">Improves Parent <br /> Satisfaction</p>
                                <p className="text-sm text-[white] font-light text-gray-300">
                                    Happy parents mean a more harmonius school environment, better student retention and more parential referrals.
                                </p>
                            </div>
                            <Heading size="5xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                Improves Parent Satisfaction
                            </Heading>
                        </div>
                    </div>
                    {/* <div className="max-w-[60%] h-[270px] rounded-lg bg-deep_purple-700 p-4">
                        <div className="h-[99%] px-[50px] border border-solid rounded-lg flex justify-center items-center text-center">
                            <Heading size="2xl" as="h4" className="!font-poppins">
                                Improves Parent Satisfaction
                            </Heading>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* communication section */}
            <div className="container-xs flex flex-col gap-12">
                <div className="container-xs px-12">
                    <Button
                        color="gray_50"
                        size="6xl"
                        variant="fill"
                        className="w-full rounded-[20px] bg-gradient2 bg-clip-text font-lexenddeca font-black text-transparent sm:px-5 border border-solid sm:hidden"
                    >
                        Outbound Comprehensive Communication
                    </Button>
                    <Button
                        color="gray_50"
                        size="4xl"
                        variant="fill"
                        className="w-full rounded-[20px] bg-gradient2 bg-clip-text font-lexenddeca font-black text-transparent sm:px-5 border border-solid hidden sm:block"
                    >
                        Outbound Comprehensive Communication
                    </Button>
                </div>
                <CommunicationSection />
            </div>

            {/* live demo cta section */}
            <div className="container-xs flex justify-center my-[40px] ">

                <Button onClick={handleModal} size="lg" shape="round" color="deep_purple_400_red_500" className="w-[35%] font-extrabold sm:px-5 sm:w-[90%]">
                    Click here for a LIVE demo
                </Button>
            </div>

            {/* risks without product section */}
            <div className="container-xs sm:mx-[5%]">
                <div className="w-full rounded-[40px] bg-gray-100_01 p-5 sm:w-[90%] sm:p-3" >
                    <div className=" m-auto flex h-max w-[94%] flex-col items-start">
                        <div className="flex w-[88%] items-center gap-8 md:w-full md:flex-col">
                            <Img src="images/img_group_black_900.svg" alt="group image" className="h-[60px] w-[7%] md:w-full" />
                            <Heading size="7xl" as="h2" className="self-end !font-lexenddeca !text-deep_orange-A400">
                                What You Stand to Lose without AUTO CONNECT
                            </Heading>
                        </div>
                        <Heading size="8xl" as="h3" className="mt-[60px] !font-lexenddeca !text-red-900 sm:mt-[30px]">
                            If you don&#39;t opt for AUTO CONNECT, you risk:
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-black-900"
                        >
                            <>
                                &gt; Missing crucial communication about their child with parents.
                                <br />
                                &gt; Experiencing lower parent satisfaction due to language barriers.
                                <br />
                                &gt; Experience Zero to low parental referral.
                                <br />
                                &gt; Incurring higher administrative costs due to manual communication methods.
                                <br />
                                &gt; Losing out on potential student retention and the opportunity to have new admissions.
                            </>
                        </Heading>
                    </div>
                </div>
            </div>

            {/* faq section */}
            <FaqSection data={FAQDATA} height="h-[450px]" isHeading />

            {/* footer section */}
            <Footer />
        </div >
    );
}

export default EffectiveCommunication;

const FAQDATA = [
    {
        question: "Drawbacks of SMS",
        answers: [
            {
                question: "Drawbacks of SMS",
                answer: [{
                    bold: "Financial Loss for Educational Institutions",
                },
                {
                    bold: "Language Barrier:",
                    answer: "In India, only 18% of people can read and                    write English. Sending SMS in English means many parents                    miss important updates because they can't understand the                    messages."
                },
                {
                    bold: "Inefficient Spending:",
                    answer: "Spending money on English SMS is not                    effective since most parents cannot understand them, leading                    to wasted resources."
                },
                {
                    bold: "Manpower Costs:",
                    answer: "Staff must follow up with calls to parents                    who missed or did not understand the SMS, increasing labour                    costs."
                }
                ]
            }
        ]
    }, {
        question: "Drawbacks of Calls",
        answers: [
            {
                question: "Drawbacks of Calls",
                answer: [{
                    bold: "Financial Loss for Educational Institutions",
                },
                {
                    bold: "Language Barrier:",
                    answer: "In India, only 18% of people can read and                    write English. Sending SMS in English means many parents                    miss important updates because they can't understand the                    messages."
                },
                {
                    bold: "Inefficient Spending:",
                    answer: "Spending money on English SMS is not                    effective since most parents cannot understand them, leading                    to wasted resources."
                },
                {
                    bold: "Manpower Costs:",
                    answer: "Staff must follow up with calls to parents                    who missed or did not understand the SMS, increasing labour                    costs."
                }
                ]
            }
        ]
    }, {
        question: "Business loss per year",
        answers: [
            {
                question: "Business loss per year",
                answer: [{
                    bold: "",
                    answer: "Unhappy parents, who miss out on critical updates about their                    child, can lead to a loss of revenue."
                },
                {
                    bold: "",
                    answer: "Additionally, the costs of calls, SMS, and manpower can total                    at least 1 lakh per year, impacting the school's financial health."
                }
                ]
            }
        ]
    }, {
        question: "Inspired by Banking/Telecom sectors",
        answers: [
            {
                question: "Inspired by Banking/Telecom sectors",
                answer: [{
                    bold: "Maximized Communication Effectiveness:",
                    answer: "Combining calls                    and SMS ensures important messages reach customers                    efficiently and reliably."
                },
                {
                    bold: "Better Customer Service: ",
                    answer: "This strategy combines the                    immediacy and personal touch of calls with the convenience                    and accessibility of SMS."
                },
                {
                    bold: "Increased Customer Satisfaction: ",
                    answer: "Ultimately leads to                    enhanced customer service and satisfaction."
                }
                ]
            }
        ]
    }, {
        question: "Are educational institutions aware of the need for better communication tools?",
        answers: [
            {
                question: "Are educational institutions aware of the need for                better communication tools?",
                answer: [{
                    bold: "",
                    answer: ">>> Yes, educational institutions recognize the need for                    better communication tools."
                },
                {
                    bold: "",
                    answer: ">>> However, unlike the banking and telecom sectors, they                    lack advanced multilingual platforms."
                },
                {
                    bold: "",
                    answer: ">>> Developing and maintaining such platforms is complex."
                },
                {
                    bold: "",
                    answer: ">>> It requires significant infrastructure and highly skilled                    technical resources."
                },
                {
                    bold: "",
                    answer: ">>> Continuous content upgrades and enhancements are                    necessary."
                },
                {
                    bold: "",
                    answer: ">>> These endeavors involve substantial costs, amounting to                    millions of rupees."
                },
                ]
            }
        ]
    }
]