import { Img, Text, Button, Heading } from "./..";
import React from "react";

interface Props {
  className?: string;
}

export default function Footer({ ...props }: Props) {
  return (
    <footer
      {...props}
      className={`${props.className} flex justify-center items-center w-full py-[60px] bg-indigo-900 sm:p-0`}
    >
      <div className="flex flex-row justify-center w-full">
        <div className="flex flex-col items-center justify-center w-full gap-[101px]">
          <div className=" w-full max-w-[1030px] sm:flex-col sm:p-4">
            <div className="flex flex-col items-start justify-start gap-[106px] w-full">
              <div className="flex flex-row justify-center items-start w-full sm:flex-col">
                <div className="flex flex-col items-start justify-start w-[18%] gap-[46px] sm:gap-[10px] sm:mt-5 sm:w-[100%]">
                  <Heading size="lg" as="h5" className="ml-px !font-playfairdisplay">
                    Top Products
                  </Heading>
                  <ul className="flex flex-col items-start justify-start w-full gap-2">
                    <li>
                      <a href="#">
                        <Text as="p">Managed Website</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Manage Reputation</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Power Tools</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Marketing Service</Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-[16%] gap-[46px] sm:gap-[10px] sm:mt-5 sm:w-[100%]">
                  <Heading size="lg" as="h5" className="ml-px !font-playfairdisplay">
                    Quick Links
                  </Heading>
                  <ul className="flex flex-col items-start justify-start w-full gap-2">
                    <li>
                      <a href="#">
                        <Text as="p">Jobs</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Brand Assests</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Investor Realtions</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Terms Of Service</Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-[16%] gap-[47px] sm:gap-[10px] sm:mt-5 sm:w-[100%]">
                  <Heading size="lg" as="h5" className="ml-px !font-playfairdisplay">
                    Features
                  </Heading>
                  <ul className="flex flex-col items-start justify-start w-full gap-2">
                    <li>
                      <a href="#">
                        <Text as="p">Jobs</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Brand Assests</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Investors Relations</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Terms Of Service</Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-[12%] gap-[47px] sm:gap-[10px] sm:mt-5 sm:w-[100%]">
                  <Heading size="lg" as="h5" className="ml-px !font-playfairdisplay">
                    Resources
                  </Heading>
                  <ul className="flex flex-col items-start justify-start gap-2">
                    <li>
                      <a href="#">
                        <Text as="p">Guides</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Research</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Experts</Text>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <Text as="p">Agencies</Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-start justify-start w-[33%] gap-[47px] sm:gap-[10px] sm:mt-5 sm:w-[100%]">
                  <Heading size="lg" as="h5" className="!font-playfairdisplay">
                    Newsletter
                  </Heading>
                  <div className="flex flex-col items-start justify-start gap-8">

                  <Text as="p" className="">
                    You can turst us. We only send promo offers.
                  </Text>
                  <div className="flex items-center justify-between gap-5 self-stretch rounded-br-[20px] rounded-tr-[20px] bg-blue_gray-800 pl-6 sm:pl-5">
                    <a href="#">
                      <Text as="p">Your Email Address</Text>
                    </a>
                    <Button
                      size="sm"
                      color="deep_purple_A200_03_purple_400_01"
                      className="min-w-[128px] rounded-[20px] font-poppins font-bold sm:px-5"
                    >
                      SUBSCRIBE
                    </Button>
                  </div>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="flex flex-row justify-between items-center w-full mt-[110px] sm:flex-col sm:mt-[30px] md:px-3">
              <Text as="p" className="!text-[#ffffff] !font-medium">
                <span className="text-[#ffffff]">
                  Soomuch Smart Solutions PVT Limited | Copyright ©2024
                </span>
              </Text>
              <Img src="images/img_group_1707479657.svg" alt="image" className="sm:m-5" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
