import classNames from "classnames";
import { Heading, Img, Text } from "..";
import React from "react";

interface Props {
    data?: any;
    height?: string;
    description?: string;
    isHeading?: boolean;
}

export default function FaqSection({
    data,
    height,
    isHeading,
    ...props
}: Props) {

    const [id, setId] = React.useState(0);

    return (
        <div className={`flex flex-col mt-[58px] pb-[46px] md:pb-5 sm:mt-2`}>
            <div className="container-xs flex flex-col items-start gap-[52px] pl-[11px] pr-2.5 md:p-5 sm:gap-[26px]">
                <Heading size="11xl" as="h1" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                    {"Frequently Asked Questions"}
                </Heading>
                <div className="self-stretch">
                    <div className="flex items-start md:flex-col">
                        <div className="relative z-[3] mt-8 w-full border border-solid border-deep_purple-A200_01">
                            <div className="flex flex-col gap-px">
                                {data?.map((item, index) => <div onClick={() => setId(index)} key={"kjsabdjwhesnbdnmwfadsa" + index} className={`cursor-pointer transition ease-in-out delay-200 flex flex-1 ${index === id ? "bg-purple-50" : "bg-white-A700"} px-4 py-6 shadow-lg sm:py-5`}>
                                    <div className="flex w-full justify-center items-center gap-6">
                                        <div className="flex flex-1 items-center gap-4">
                                            <div className={`transition ease-in-out delay-200 min-h-[24px] min-w-[24px] rounded-[12px] ${index === id ? "bg-deep_purple-A400" : "bg-purple-100"}`} />
                                            <Text size="s" as="p" className="!font-inter !text-black-900">
                                                {item?.question}
                                            </Text>
                                        </div>
                                        <Img src="images/img_arrow_right.svg" alt="faq arrow image" className="h-[24px] w-[24px]" />
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        <div className={`${!!height ? height : "h-[510px]"} transition ease-in-out relative ml-[-88px] flex w-full flex-col items-start  rounded-[16px] border border-solid border-deep_purple-A200_01 bg-white-A700 pb-[42px] pl-36 pr-8 pt-[43px] shadow-md sm:p-4 md:m-0`}>
                            {isHeading && <Heading size="xl" as="h6" className="transition ease-in-out w-full !font-inter leading-6 !text-black-900 leading-8 pr-2">
                                {data?.[id]?.answers?.[0]?.question}
                            </Heading>}
                            <Heading size="md" as="h6" className="transition ease-in-out overflow-auto w-full !font-inter leading-6 !text-black-900 leading-8 pr-2">
                                {data?.[id]?.answers?.map((item, index) => <React.Fragment key={"askdbkwjskdsadasdad" + index}>
                                    {(!!item?.question && !isHeading) && <span className="transition ease-in-out delay-200 text-black-900">
                                        {item?.question}
                                    </span>}
                                    <br />
                                    {item?.answer?.map((innerItem, innerIndex) => <React.Fragment>
                                        {!!innerItem?.bold && <span className="transition ease-in-out delay-200 text-black-900">
                                            {innerItem?.bold}{" "}
                                        </span>}
                                        {!!innerItem?.answer && <span className="transition ease-in-out delay-200 font-normal text-black-900">
                                            {innerItem?.answer}
                                        </span>}
                                        <br />
                                    </React.Fragment>)}
                                    <br />
                                </React.Fragment>)}
                            </Heading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}