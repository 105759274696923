import React, { useEffect } from "react";
import * as ModalR from 'react-modal';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikProps, ErrorMessage } from 'formik';
import { Button } from "components/Button";

const AfterSubmitionModal = ({
    showModal, customStyles, setshowModal
}) => {

    return (
        <ModalR
            isOpen={showModal?.display}
            contentLabel="Example Modal"
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '40rem',
                    maxWidth: '90%',
                    minHeight: '6rem',
                    borderRadius: '1em',
                    boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
                    padding: '0px',
                    border: '0px'
                },
                overlay: {
                    background: 'rgba(0, 0, 0, 0.4)',
                    zIndex: '999',
                },
            }}
        >
            <div className="flex justify-between items-center bg-[#8933F9] py-2">
                <div className="w-10"></div>
                <h1 className="font-bold text-lg text-[white]">After Demo Request Submittion</h1>
                <div onClick={() => setshowModal({ display: false, ID: 0 })} className="cursor-pointer bg-[white] rounded-lg p-1 mr-1">
                    <img src="/images/Close.svg" alt="close" className="h-6" />
                </div>
            </div>
            <div className="px-8 py-2 overflow-y-auto max-h-[500px]">
                <div className="flex justify-between">
                    <div className="flex flex-col gap-8">
                        <div className="flex gap-4 items-center">
                            <img src="images/modal_pointer1.svg" alt="" className="h-10" />
                            <p className="font-bold">Duration Of Demo : 30 seconds - 1 minute</p>
                        </div>
                        <div className="flex gap-4 items-center">
                            <img src="images/modal_pointer2.svg" alt="" className="h-10" />
                            <p className="font-bold">You will receive the call from : +91 80 6905 9001 Please Save it on the Name : SOOMUCH.AI</p>
                        </div>
                    </div>
                    <img src="images/call_center_boy.svg" alt="" className="h-[150px] float-right" />
                </div>
                <div className="flex gap-4 items-center">
                    <img src="images/modal_pointer3.svg" alt="" className="h-10" />
                    <p className="font-bold">Once after receiving, a call please feel free to choose preferred language from Telugu, Hindi and English for Demo.</p>
                </div>
                <div className="flex flex-col items-center px-12 py-2 gap-4">
                    <div className="space-y-1">
                        <p>For Example:</p>
                        <div className="flex justify-center items-center gap-4">
                            <div>Press 1 for  Telugu</div>
                            <div>|</div>
                            <div>Press 2 for English</div>
                            <div>|</div>
                            <div>Press 3 for Hindi</div>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src="images/call_coming.svg" alt="" className="h-6" />
                        <p className="text-[red] font-bold underline">You will receive the call in next 5 minutes.</p>
                    </div>
                </div>
                <div className="flex justify-center items-end gap-4">
                    <p className="text-md font-bold">“Thank You for Allowing Us to give a Live Demo Our Application !“</p>
                    <img src="images/thankyou.svg" alt="" className="h-[50px]" />
                </div>
            </div>
        </ModalR>
    );
}

export default AfterSubmitionModal;