import React, { useEffect } from 'react';

const SlidingCard = ({data}) => {

    const handleParentHover = (event) => {
        event.target.classList.add('transition');
    };

    const handleParentLeave = (event) => {
        event.target.classList.remove('transition');
    };

    // useEffect(() => {
    //     const handleMouseOver = (event) => {
    //         event.target.classList.add('transition');
    //     };

    //     const handleMouseOut = (event) => {
    //         event.target.classList.remove('transition');
    //     };

    //     const cards = document.querySelectorAll('.cards_SLIDE_SOOMUCH');

    //     const addEventListeners = (elements) => {
    //         elements.forEach((element) => {
    //             element.addEventListener('mouseover', (event) => {
    //                 event.target.classList.add('transition');
    //             });
    //             element.addEventListener('mouseout', (event) => {
    //                 event.target.classList.remove('transition');
    //             });
    //         });
    //     };

    //     addEventListeners(cards);
    //     // addEventListeners(cardStacks);
    //     // addEventListeners(cardsSplit);
    //     // addEventListeners(cardsSplitDelay);

    //     // Cleanup function to remove event listeners
    //     return () => {
    //         const removeEventListeners = (elements) => {
    //             elements.forEach((element) => {
    //                 element.removeEventListener('mouseover', handleMouseOver);
    //                 element.removeEventListener('mouseout', handleMouseOut);
    //             });
    //         };

    //         removeEventListeners(cards);
    //         // removeEventListeners(cardStacks);
    //         // removeEventListeners(cardsSplit);
    //         // removeEventListeners(cardsSplitDelay);
    //     };
    // }, []);

    // const handleMouseOver = (event) => {
    //     const cards = document.querySelectorAll('.cards_SLIDE_SOOMUCH');
    //     const addEventListeners = (elements) => {
    //         elements.forEach((element) => {
    //             element.addEventListener('mouseover', (event) => {
    //                 event.target.classList.add('transition');
    //             });
    //             element.addEventListener('mouseout', (event) => {
    //                 event.target.classList.remove('transition');
    //             });
    //         });
    //     };

    //     addEventListeners(cards);
    //   };

    //   const handleMouseOut = (event) => {
    //     event.target.classList.remove('transition');
    //   };

    return (
        <ul className="cards_SLIDE_SOOMUCH"
            onMouseEnter={handleParentHover}
            onMouseLeave={handleParentLeave}
        >
            {data?.map((item, index) =>  <li key={"asdwqjnnasdnwbmeasd"+index} className={`card_SLIDE_SOOMUCH card-${index+1}_SLIDE_SOOMUCH${data?.length > 3 ? "_4" : ""}`}>
                <div className="content_SLIDE_SOOMUCH space-y-4">
                    <p className="text-[white] font-bold text-xl">{item?.title}</p>
                </div>
                <div className="content_SLIDE_SOOMUCH space-y-4">
                    <p className="text-[white]">{item?.des}</p>
                </div>
            </li>)}
            {/* <li className="card_SLIDE_SOOMUCH card-1_SLIDE_SOOMUCH">
                <div className="content_SLIDE_SOOMUCH text-center space-y-4">
                    <p className="text-[white] font-bold text-3xl">Go Digital</p>
                    <p className="text-sm text-[white]"> Embrace the power of digital marketing with Social Hub.</p>
                </div>
            </li>
            <li className="card_SLIDE_SOOMUCH card-2_SLIDE_SOOMUCH">
                <div className="content_SLIDE_SOOMUCH text-center space-y-4">
                    <p className="text-[white] font-bold text-3xl">Go Digital</p>
                    <p className="text-sm text-[white]"> Embrace the power of digital marketing with Social Hub.</p>
                </div>
            </li>
            <li className="card_SLIDE_SOOMUCH card-3_SLIDE_SOOMUCH">
                <div className="content_SLIDE_SOOMUCH text-center">
                    <h1>Card 3 Title</h1>
                    <p>Card description</p>
                </div>
            </li> */}
        </ul>
    );
};

export default SlidingCard;
