import React from 'react';
import { Heading } from 'components/Heading';
import Swal from 'sweetalert2';

const Privacy = () => {

    return <div className='container-xs py-8 space-y-6 sm:p-6'>
        <h1 className="text-4xl">Privacy Policy</h1>
        <p>
            This privacy policy (“Policy”) relates to the manner in which SooMuch Smart Solutions Pvt Ltd (“we”, “us”, “our”) uses, handles, and processes the data provided by schools through our Education Software ERP and Mobile Apps for parents, staff, principals, and students. By using our products or services, you agree to the terms and conditions of this Policy and consent to our use, storage, disclosure, and transfer of your information or data as described herein.
        </p>
        <p>
            We are committed to ensuring that your privacy is protected in accordance with applicable laws and regulations. We urge you to familiarize yourself with this Policy to understand how your data is handled by us.
        </p>
        <p>
            SooMuch Smart Solutions Pvt Ltd may update this Policy periodically, and we encourage you to check this page for the latest version to stay informed.
        </p>

        <h2 className="text-2xl">Data Collection</h2>
        <p>
            The following information may be collected by schools using our ERP system:
        </p>
        <ul className="list-disc list-inside space-y-3">
            <li>Name</li>
            <li>Contact information, including address and email address</li>
            <li>Demographic information, preferences, or interests</li>
            <li>Personal Data or other information relevant/required for providing educational services</li>
        </ul>
        <p>
            <strong>Note:</strong> As required by applicable Indian laws, we do not store any credit card, debit card, or similar card data. All data collected will be strictly in accordance with applicable laws and guidelines.
        </p>

        <h2 className="text-2xl">Data Usage</h2>
        <p>
            We require this data to provide educational services, including but not limited to:
        </p>
        <ul className="list-disc list-inside space-y-3">
            <li>Internal record keeping</li>
            <li>Improving our products or services</li>
            <li>Providing updates about our products or services, including special offers</li>
            <li>Communicating information to users</li>
            <li>Internal training and quality assurance purposes</li>
        </ul>

        <h2 className="text-2xl">Data Sharing</h2>
        <p>
            We do not share your information or data with third parties, except in the following circumstances:
        </p>
        <ul className="list-disc list-inside space-y-3">
            <li>With our group companies (to the extent relevant)</li>
            <li>Our auditors or advisors to the extent required for performing their services</li>
            <li>Governmental bodies, regulatory authorities, or law enforcement authorities pursuant to our legal obligations or compliance requirements</li>
        </ul>

        <h2 className="text-2xl">Cookies</h2>
        <p>
            We use "cookies" to collect information and better understand user behavior. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, refusing cookies may limit your ability to use our services fully. We do not control the use of cookies by third parties. Third-party service providers have their own privacy policies regarding their use of such information.
        </p>

        <h2 className="text-2xl">User Rights</h2>
        <p>
            <strong>Right to Review:</strong> You can review the data provided by you and request corrections or amendments (to the extent feasible, as determined by us). We are not responsible for the authenticity of the data provided by you.
        </p>
        <p>
            <strong>Withdrawal of Consent:</strong> You can choose not to provide your data at any time while using our services or withdraw your consent in writing by contacting us at <a href="mailto:support@soomuch.ai">support@soomuch.ai</a>. Please note that if you withdraw your consent, we may not be able to provide our services to you. These rights are subject to compliance with applicable laws.
        </p>

        <h2 className="text-2xl">Data Retention</h2>
        <p>
            We may retain your data (i) for as long as we provide services to you and (ii) as permitted under applicable law, even after you terminate the business relationship with us. We will process such data in accordance with applicable laws and this Policy.
        </p>

        <h2 className="text-2xl">Data Security</h2>
        <p>
            We use commercially reasonable and legally required precautions to preserve the integrity and security of your data.
        </p>

        <h2 className="text-2xl">Queries/Grievance Officer</h2>
        <p>
            For any queries, questions, or grievances about this Policy, please contact us using the contact information provided on our website.
        </p>

        <p>
            By SooMuch Smart Solutions Pvt Ltd, providing Education Software ERP and Mobile Apps for parents, staff, principals, and students. We do not collect data directly; schools collect data using our ERP system.
        </p>
    </div>
};

export default Privacy;
