import React from "react";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[10px]",
} as const;
const variants = {
  fill: {
    purple_900: "bg-purple-900 text-white-A700_01",
    white_A700_01: "bg-white-A700_01 shadow-sm text-black-900",
    gray_50: "bg-gray-50 shadow-xl",
    purple_A200_01: "bg-purple-A200_01",
    purple_A200_02: "bg-purple-A200_02",
  },
  gradient: {
    deep_purple_400_red_500: "bg-gradient shadow-xs text-white-A700_01",
    deep_purple_A200_03_purple_400_01: "bg-gradient1 text-white-A700_01",
  },
} as const;
const sizes = {
  xs: "h-[40px] px-[35px] text-lg",
  "3xl": "h-[60px] px-7 text-[21px]",
  "6xl": "h-[104px] px-[35px] text-[40px]",
  sm: "h-[44px] px-[22px] text-[15px]",
  xl: "h-[56px] pl-4 pr-[9px] text-base",
  "5xl": "h-[100px]",
  lg: "h-[56px] px-[35px] text-[25px]",
  "2xl": "h-[60px] px-[18px]",
  "4xl": "h-[64px] px-[18px]",
  md: "h-[45px] px-3.5 text-xl",
} as const;

type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> &
  Partial<{
    className: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    color: string;
  }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "gradient",
  size = "md",
  color = "purple_900",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
