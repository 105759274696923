import React from "react";
import * as ModalR from 'react-modal';
import * as Yup from 'yup';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Button } from "components/Button";
import axios from "axios";
// import moment from "moment";
import Swal from "sweetalert2";

const BRANCH_COUNT_LIST = [...Array.from({ length: 10 }, (_, i) => i + 1), "More than 10"];
const TYPE_OF_ORG_LIST = ['School', 'College', 'Coaching', 'Engineering', 'Graduation', 'Post Graduation'];
const LANGUAGE_LIST = ["English", "Telugu", "Hindi - COMING SOON"];
const ROLE_LIST = ['Principal', 'Faculty', 'Owner', 'Others'];

const SOURCE_NAME = {
    "/": "COMM",
    "/customer-satisfaction": "FEEDBACK",
    "/fee-collection-process": "FEE",
}

// const getCurrentDateAndTime = (given, into12format = false, toSend?) => {
//     if (moment(given).isValid()) {
//         if (toSend)
//             return moment(given).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
//         if (!into12format) {
//             return moment.utc(given).local().format('YYYY-MM-DD HH:mm:ssZ');
//         }
//         return moment.utc(given).local().format('DD/MM/YYYY hh:mm A');
//     }
//     return moment.utc(new Date()).local().format('DD/MM/YYYY hh:mm A');
// };

const customStylesAskModal = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '30rem',
        maxWidth: '80%',
        minHeight: '10rem',
        borderRadius: '1.5em',
        boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
        padding: '1.3em',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.4)',
        zIndex: 999
    },
};

const Modal = ({
    showModal, customStyles, setshowModal, handleSuccessModal
}) => {

    const [confirmationModal, setConfirmationModal] = React.useState({ display: false, data: {} });
    const [formLoader, setFormLoader] = React.useState(false);
    const [demoCampaignID, setDemoCampaignID] = React.useState({
        DEMO_CAMPAIGN_ID: 0,
        SOURCE: SOURCE_NAME?.[window?.location?.pathname],
        OTP: ""
    });

    const initialValues = {
        NAME: '',
        LANGUAGE: '',
        INSTITUTE_NAME: '',
        INSTITUTE_TYPE: '',
        MOBILE_NUMBER: '',
        SALES_USER_NUMBER: '',
        BRANCH_COUNT: '',
        OTP: '',
        SALES_USER_OTP: '',
        ROLE: '',
        REMARKS: '',
    };

    const validationSchema = (IS_OTP_VALIDATION_REQUIRED) => Yup.object({
        NAME: Yup.string().required('Required'),
        LANGUAGE: Yup.string().required('Required'),
        INSTITUTE_NAME: Yup.string().required('Required'),
        MOBILE_NUMBER: Yup.number().required('Required').label("Mobile Number"),
        SALES_USER_NUMBER: Yup.number().required('Required').label("Mobile Number"),
        BRANCH_COUNT: Yup.number().required('Required').label("Branch Count"),
        ...(IS_OTP_VALIDATION_REQUIRED && {
            OTP: Yup.number().required('Required').label("OTP"),
            SALES_USER_OTP: Yup.number().required('Required').label("Sales User OTP")
        })
    });

    const handleInsert = async (data) => {

        try {
            const insertResponse = await axios.post(`https://stg.soomuch.ai/api/v1/master/demoCampaign/insert`, data);
            if (insertResponse?.data?.data?.DEMO_CAMPAIGN_ID) {
                setDemoCampaignID(demoCampaignID => ({
                    ...demoCampaignID,
                    DEMO_CAMPAIGN_ID: insertResponse?.data?.data?.DEMO_CAMPAIGN_ID,
                }))
            } else {
                Swal.fire({
                    icon: "error",
                    title: insertResponse?.data?.message,
                    showConfirmButton: false,
                    timer: 3000
                });
            }
            setFormLoader(false);
        } catch (err) {
            Swal.fire({
                icon: "error",
                title: err?.message,
                showConfirmButton: false,
                timer: 3000
            });
            setFormLoader(false);
        }
    }

    const handleSubmit = async (values) => {
        try {
            setFormLoader(true);
            // Handle form submission
            if (demoCampaignID?.DEMO_CAMPAIGN_ID) {
                const payloadValue: any = {
                    ...demoCampaignID,
                    OTP: values?.OTP,
                    SALES_USER_OTP: values?.SALES_USER_OTP,
                };

                // console.log("stroage start");

                localStorage.setItem('demo_data', JSON.stringify(payloadValue));



                localStorage.setItem('form_data', JSON.stringify(values));


                // console.log("stroage end");



                const campaginDetailResponse = await axios.post(`https://stg.soomuch.ai/api/v1/master/demoCampaignDetail/insert`, payloadValue);
                setFormLoader(false);
                handleSuccessModal({ display: false, ID: 0 });
            } else {
                const isDataIsAlreadyThere = await axios.get(`https://stg.soomuch.ai/api/v1/master/demoCampaign/check?mobile_number=${values?.MOBILE_NUMBER}`);
                if (isDataIsAlreadyThere?.data?.data?.exists) {
                    setFormLoader(false);
                    setConfirmationModal({ display: true, data: values })
                } else {
                    handleInsert(values);
                }
            }
        } catch (err) {
            console.log(err);
            setFormLoader(false);
        }

    };

    return (
        <ModalR
            isOpen={showModal?.display}
            contentLabel="Example Modal"
            style={customStyles}
        >
            {confirmationModal.display && <ModalR
                isOpen={confirmationModal.display}
                contentLabel="Example Modal"
                style={customStylesAskModal}
            >
                <div className="flex justify-between">
                    <h1 className="font-bold text-xl">Confirmation</h1>
                    <button onClick={() => setConfirmationModal({ display: false, data: {} })}>
                        <img src="/images/Close.svg" alt="close" className="h-8" />
                    </button>
                </div>
                <p className="text-medium text-md text-neutral-70 my-[1.5em]">
                    The demo for this number has already been completed. Are you sure you want to proceed with another demonstration?
                </p>
                <div className="flex justify-end">
                    <div className="flex gap-2">
                        <Button
                            size="sm"
                            type="button"
                            className="font-semibold rounded-lg"
                            onClick={() => {
                                setConfirmationModal({ display: false, data: {} });
                                setshowModal({ display: false, data: {} });
                            }}
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                handleInsert(confirmationModal?.data);
                                setConfirmationModal({ display: false, data: {} });
                            }}
                            type="button"
                            size="sm"
                            color="deep_purple_400_red_500"
                            className="!bg-gradient font-semibold rounded-lg"
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </ModalR>}
            <div className="flex justify-between items-center bg-[#8933F9] py-2">
                <div className="w-10"></div>
                <h1 className="font-bold text-2xl text-[white]">Live Demo</h1>
                <div onClick={() => setshowModal({ display: false, ID: 0 })} className="cursor-pointer bg-[white] rounded-lg p-2 mr-1">
                    <img src="/images/Close.svg" alt="close" className="h-8" />
                </div>
            </div>
            <div className="px-8 py-2 overflow-y-auto max-h-[500px]">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(demoCampaignID?.DEMO_CAMPAIGN_ID)}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange }) => (
                        <Form className="flex flex-col gap-4 mb-5">
                            <div className="grid grid-cols-3 gap-4 sm:grid-cols-1">
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Your Name <span className="text-red-700">*</span></label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="NAME"
                                            className="p-2 rounded h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="NAME" component="div" className="text-red-600" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Institution Name <span className="text-red-700">*</span></label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="INSTITUTE_NAME"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="INSTITUTE_NAME" component="div" className="text-red-600" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Language <span className="text-red-700">*</span></label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field as="select" name="LANGUAGE" className="p-2 rounded h-10 w-[100%]">
                                            <option disabled value="">Select Language</option>
                                            {LANGUAGE_LIST?.map(item => <option disabled={item === "Hindi - COMING SOON"} key={"languageDropdown" + item} value={item}>{item}</option>)}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="LANGUAGE" component="div" className="text-red-600" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Branch Count <span className="text-red-700">*</span></label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field as="select" name="BRANCH_COUNT" className="p-2 rounded h-10 w-[100%]">
                                            <option disabled value="">Select Branch Count</option>
                                            {BRANCH_COUNT_LIST?.map(item => <option key={"branchDropdown" + item} value={item}>{item}</option>)}
                                        </Field>
                                    </div>
                                    <ErrorMessage name="BRANCH_COUNT" component="div" className="text-red-600" />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Type Of Organization</label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field as="select" name="INSTITUTE_TYPE" className="p-2 rounded h-10 w-[100%]">
                                            <option disabled value="">Select Organization</option>
                                            {TYPE_OF_ORG_LIST?.map(item => <option key={"TYPE_OF_ORG_LISTDropdown" + item} value={item}>{item}</option>)}
                                        </Field>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Your Role</label>
                                    <div className="w-[100%] border border-solid border-black-900 rounded-lg">
                                        <Field as="select" name="ROLE" className="p-2 rounded h-10 w-[100%]">
                                            <option disabled value="">Select Role</option>
                                            {ROLE_LIST?.map(item => <option key={"ROLE_LISTDropdown" + item} value={item}>{item}</option>)}
                                        </Field>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Remarks</label>
                                    <div className="border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="REMARKS"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Mobile No/Whatsapp No <span className="text-red-700">*</span></label>
                                    <div className="border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="MOBILE_NUMBER"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="MOBILE_NUMBER" component="div" className="text-red-600" />
                                </div>
                                {!!demoCampaignID?.DEMO_CAMPAIGN_ID && <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">OTP <span className="text-red-700">*</span></label>
                                    <div className="border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="OTP"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="OTP" component="div" className="text-red-600" />
                                </div>}
                                <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Sales User Mobile No <span className="text-red-700">*</span></label>
                                    <div className="border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="SALES_USER_NUMBER"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="SALES_USER_NUMBER" component="div" className="text-red-600" />
                                </div>
                                {!!demoCampaignID?.DEMO_CAMPAIGN_ID && <div className="flex flex-col gap-2">
                                    <label className="block text-gray-700">Sales User OTP <span className="text-red-700">*</span></label>
                                    <div className="border border-solid border-black-900 rounded-lg">
                                        <Field
                                            type="text"
                                            name="SALES_USER_OTP"
                                            className="p-2 h-10 w-[100%]"
                                        />
                                    </div>
                                    <ErrorMessage name="SALES_USER_OTP" component="div" className="text-red-600" />
                                </div>}
                                <div className={`${!!demoCampaignID?.DEMO_CAMPAIGN_ID ? "w-full flex justify-center items-center" : "col-span-3 flex justify-center"}`}>
                                {formLoader ? <img src="images/loader.gif" alt="loader" className="h-10" /> : <Button
                                    size="sm"
                                    color="deep_purple_400_red_500"
                                    className={`${!!demoCampaignID?.DEMO_CAMPAIGN_ID ? "w-[50%]": "w-[20%]"} !bg-gradient font-semibold rounded-lg sm:w-[50%]`}
                                >
                                    Submit
                                </Button>}
                            </div>
                            </div>
                            {/* <div className="flex justify-center">
                                {formLoader ? <img src="images/loader.gif" alt="loader" className="h-10" /> : <Button
                                    size="sm"
                                    color="deep_purple_400_red_500"
                                    className="w-[20%] !bg-gradient font-semibold rounded-lg sm:w-[50%]"
                                >
                                    Submit
                                </Button>}
                            </div> */}
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalR>
    );
}

export default Modal;