import { Helmet } from "react-helmet";
import { Heading, Img, Button, Text } from "../../components";
import Faq from "../../components/Faq";
import CommunicationSection from "../../components/CommunicationSection";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom';
import HeroTitle from "components/HeroTitle";
import SlidingCard from "components/SlidingCard";
import { FlipCard } from "react-flipme";
import FaqSection from "components/FaqSection";
import { Link as AnchorTag } from 'react-scroll';
import InboundButton from "components/InboundButton";

const data = [
    { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
    { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
    { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
    { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
    { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

const data1 = [
    { appsImage: "images/solution1.svg", appsDescription: "Listen to Parental Feedback", des: "Listen to what parents say in surveys and feedback sessions to improve their experience." },
    { appsImage: "images/solution2.svg", appsDescription: "Implement Changes", des: "Fix problems quickly and tell parents what you're doing to make things better." },
    { appsImage: "images/solution3.svg", appsDescription: "Keep Parents Informed", des: "Keep parents in the loop about changes based on their feedback to build trust." },
];

const mobileSection = [
    { appsImage: "images/mobile_super_admin.svg", appsDescription: "Super Admin App" },
    { appsImage: "images/parent_app.svg", appsDescription: "Parent App" },
    { appsImage: "images/faculty_app.svg", appsDescription: "Faculty App" },
];

const unlockSoomuchAISection = [
    { appsImage: "images/rapid_learning.svg", appsDescription: "Rapid Learning" },
    { appsImage: "images/effortless_operations.svg", appsDescription: "Effortless Operation" },
    { appsImage: "images/intelligent_search.svg", appsDescription: "Intelligent Search" },
];

const tailoredSection = [
    { appsImage: "images/flexiblity.svg", appsDescription: "Flexibility" },
    { appsImage: "images/complementry_customization.svg", appsDescription: "Complimentary Customization" },
    { appsImage: "images/seamless_fit.svg", appsDescription: "Seamless Fit" },
];

const revolutionizingSection = [
    { appsImage: "images/instance_assistance.svg", appsDescription: "Instant Assistance" },
    { appsImage: "images/effeciency_unleashed.svg", appsDescription: "Efficiency Unleashed" },
    { appsImage: "images/accessable_in_30_sec.svg", appsDescription: "Accessible in 30 Seconds" },
    { appsImage: "images/seamless_user_experience.svg", appsDescription: "Seamless User Experience" },
];

const CustomerSatisfaction = ({ handleModal }) => {
    let demoData: any = '';

    try {
        console.log("checking");
        demoData = JSON.parse(localStorage.getItem('demo_data')) || "";
    } catch (err) {
        console.log("err ", err);
    }

    return (
        <div className="flex flex-col gap-8">
            {/* navigation bar section */}

            {/* hero section */}
            <div className="flex flex-col gap-[50px]">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 md:items-end">
                        <Button
                            size="xs"
                            variant="fill"
                            className="min-w-[250px] hidden md:block rounded-bl-[10px] rounded-tl-[10px] font-extrabold sm:px-5"
                        >
                            Patent Filed
                        </Button>
                    </div>

                    <div className="flex flex-row relative">
                        <div className="container-xs md:p-5 md:px-5">
                            <div className="flex flex-col items-center gap-1 sm:text-center">
                                <Heading size="8xl" as="h1" className="!font-poppins !text-black-900_b2">
                                    India’s First Business Ecosystem for{" "}
                                </Heading>
                                <HeroTitle />
                            </div>
                        </div>
                        <Button
                            size="xs"
                            variant="fill"
                            className="absolute top-2 right-0 min-w-[250px] rounded-bl-[10px] rounded-tl-[10px] font-extrabold md:hidden"
                        >
                            Patent Filed
                        </Button>
                    </div>
                    <div className="relative self-stretch">
                        <div className="flex w-full flex-col">
                            <div className="relative z-[1] flex flex-col items-center">
                                <div className="grid grid-cols-5 px-2 h-[9.3vh] md:h-[7.3vh] gap-12 sm:h-auto sm:gap-2 sm:px-4">
                                    <Link to={"/"}>
                                        <img src="/images/img_a1.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/digital-marketing"}>
                                        <img src="/images/img_a2_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/customer-satisfaction"}>
                                        <img src="/images/img_a3_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/fee-collection-process"}>
                                        <img src="/images/img_a4.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/ai-powered-managment-hub"}>
                                        <img src="/images/img_a5.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                </div>
                                <div className="absolute container-xs top-[85px] left-[63%] sm:hidden">
                                    <Img src="images/next-arrow.gif" alt="arrow image" className="h-[30px] rotate-[120deg]" />
                                </div>
                                <div className="container-xs relative z-[-1] mt-[-2px] pl-[303px] mt-[8px] sm:pl-0 sm:pr-[170px] sm:w-[90%] md:pl-[20px] sm:m-0">
                                    <Img src="images/img_arrow_3.svg" alt="arrow image" className="h-[130px] sm:h-[100px] sm:w-[100%] md:w-[50%] md:h-[150px]" />
                                </div>
                            </div>
                            <div className="container-xs relative pl-[35px] pr-9 md:p-5 sm:px-5 sm:py-0">
                                <div className="flex items-center justify-between items-stretch gap-5 md:flex-col">
                                    <div className=" py-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[39%] flex-col items-center gap-3 rounded-tl-[52px] rounded-br-[52px] border border-solid bg-gradient5  md:w-full sm:p-5">
                                        <Heading
                                            size="s"
                                            as="h3"
                                            className="w-[95%] text-center !font-semibold leading-[22px] !text-black-900 md:w-full"
                                        >
                                            Ignites powerful word of mouth advertising and increases referrals, leading to a revenue boost of at least 1 Lakh per year.
                                        </Heading>
                                        <div className="flex w-[92%] justify-between gap-5 md:w-full sm:flex-col sm:items-center">
                                            <Button
                                                // disabled={demoData?.SOURCE === "FEEDBACK" || demoData?.SOURCE_ARRAY?.includes("FEEDBACK")}
                                                color="white_A700_01"
                                                size="xl"
                                                variant="fill"
                                                shape="round"
                                                className="min-w-[160px] font-medium"
                                                onClick={handleModal}
                                            >

                                                {!!demoData?.DEMO_CAMPAIGN_ID ? "Live Demo" : "Book a LIVE Demo"}
                                            </Button>
                                            <AnchorTag
                                                activeClass="active"
                                                to={"AUTO_CONNECT"}
                                                spy={true}
                                                smooth={true}
                                                offset={-120}
                                                duration={1000}
                                            >
                                                <Button
                                                    size="xl"
                                                    variant="fill"
                                                    shape="round"
                                                    className="min-w-[160px] font-medium shadow-sm sm:px-5"
                                                >
                                                    Know More
                                                </Button>
                                            </AnchorTag>
                                        </div>
                                    </div>
                                    <div className="pb-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[35%] justify-center rounded-tl-[52px] rounded-br-[52px] bg-gradient5 md:w-full">
                                        <div className="flex w-full flex-col items-center gap-[9px] px-6">
                                            <Heading
                                                size="s"
                                                as="h4"
                                                className="flex items-center justify-center rounded-bl-[10px] rounded-br-[10px] bg-purple-900 px-[22px] py-[3px] !font-reemkufi sm:px-5"
                                            >
                                                POTENTIAL EARNINGS
                                            </Heading>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Earning minimum:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>1,00,000
                                                </Text>
                                            </div>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Total Earnings:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>6,00,000
                                                </Text>
                                            </div>
                                            <Heading size="md" as="h6" className="!font-semibold !text-red-A700_99">
                                                *Above data is subject to Terms and Conditions.
                                            </Heading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 right-0 top-[37%] z-[2] m-auto flex w-[35%] items-center justify-center gap-2 rounded-md border border-solid border-black-900 bg-gray-100_02 sm:relative sm:hidden md:top-[15%] md:w-[60%]">
                            <Button
                                size="md"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[29%] rounded-bl-md rounded-br-2xl rounded-tl-md rounded-tr-2xl !bg-gradient font-poppins font-bold"
                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="w-[71%] text-center leading-[23px] !text-black-900 sm:w-full sm:p-5">
                                <>
                                    Making it possible for one worker to do <br />
                                    the job of several, saving at least 1 Lakh
                                </>
                            </Text>
                        </div>

                        <div className="absolute hidden left-[10%] right-0 sm:top-[8%] z-[2] m-auto sm:flex items-center justify-center rounded-md border border-solid border-black-900 bg-gray-100_02 sm:w-[80%]">
                            <Button
                                size="xl"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[20%] sm:p-0 rounded-bl-md rounded-br-xl rounded-tl-md rounded-tr-xl !bg-gradient font-poppins font-bold sm:h-[40px] sm:text-xs"                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="text-center leading-[18px] !text-black-900 sm:w-full px-1 sm:text-xs">
                                <>
                                    Making it possible for one worker to do
                                    the job of several, saving at least 1 Lakh
                                </>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xs my-2">
                <Img
                    src="images/img_mask_group.png"
                    alt="masked image"
                    className="h-[10px] w-full rounded-md object-cover"
                />
            </div>

            {/* about section */}
            <AboutSection />

            {/* features section */}
            <div id={"AUTO_CONNECT"} className="mt-[50px] flex flex-col bg-gray-100_01 pb-[50px] pt-[45px] md:py-5">
                <div className="container-xs flex flex-col items-center gap-[47px] pl-[37px] pr-[34px] md:p-5 sm:px-5">
                    <div className="flex flex-col items-start gap-10 self-stretch">
                        <div className="flex w-[95%] items-center gap-[41px] md:w-full md:flex-col sm:flex-col-reverse">
                            <Img
                                src="images/img_group_151x150_3.svg"
                                alt="logo image"
                                className="h-[151px] w-[150px] object-cover md:w-full md:object-contain	sm:m-auto"
                            />
                            <Heading
                                size="8xl"
                                as="h2"
                                className="w-[86%] bg-gradient2 bg-clip-text text-center !font-lexenddeca leading-[50px] !text-transparent md:w-full sm:leading-[40px]"
                            >
                                <span className="bg-gradient2 bg-clip-text text-transparent">
                                    Parental Insight: Focusing <br /> on customer satisfaction
                                </span>
                            </Heading>
                        </div>
                        <Heading
                            size="9xl"
                            as="h3"
                            className="ml-3 bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent md:ml-0"
                        >
                            <span className="bg-gradient2 bg-clip-text text-transparent">Why Choose&nbsp;</span>
                            <span className="bg-gradient2 bg-clip-text text-[43px] text-transparent">Parental Insight ?</span>
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="ml-[27px] w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-gray-900_01 md:ml-0"
                        >
                            <>
                                Parental Insight is all about keeping parents happy. We do this by asking them about their experiences through surveys every three months. We use calls, SMS, WhatsApp, and mobile apps to gather their thoughts.
                            </>
                        </Heading>
                    </div>
                    <div className="flex w-[92%] flex-col items-center justify-center gap-11 rounded-[40px] bg-deep_purple-100 px-14 pb-[166px] pt-[139px] md:w-full md:p-5">
                        <Button size="5xl" shape="square" className="w-[100px]">
                            <Img src="images/img_ellipse_557.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.94px] !text-deep_purple-700">
                            VIDEO GOES HERE
                        </Heading>
                    </div>
                    <InboundButton />
                    {/* <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Ready to Transform Your Communication?
                        </Heading>
                        <Button
                            size="lg"
                            shape="round"
                            color="deep_purple_400_red_500"
                            className="min-w-[392px] font-extrabold sm:px-5"
                            onClick={handleModal}
                        >
                            Click here for a LIVE demo
                        </Button>
                    </div> */}
                </div>
            </div>
            {/* <div className="grid grid-cols-12 content-stretch rounded-lg bg-gray-100_01 p-4 mx-[25px]">
            <div className="col-span-4 text-center">
              <Heading size="6xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                Action for Educational Institutions
              </Heading>
            </div>
            <div className="col-span-8"></div>
          </div> */}

            {/* risks without product section */}
            <div className="container-xs relative rounded-[40px] bg-gray-100_01 py-8 px-4 sm:p-3 sm:mx-[5%] sm:w-[90%]">
                <div className="m-auto flex h-max w-[94%] flex-col items-start">
                    <div className="flex w-[95%] items-center gap-8 md:w-full md:flex-col">
                        <Img src="images/img_group_black_900.svg" alt="group image" className="h-[60px] w-[7%] md:w-full" />
                        <Heading size="8xl" as="h2" className="self-end !font-lexenddeca !text-deep_orange-A400">
                            What You Stand to Lose without Parental Insight
                        </Heading>
                    </div>
                    <Heading size="8xl" as="h3" className="my-8 !font-lexenddeca !text-red-900 sm:mt-[30px]">
                        If you don't opt for Parental Insight, you risk:
                    </Heading>
                    <Heading
                        size="8xl"
                        as="h4"
                        className="w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-black-900"
                    >
                        <>
                            &gt;  Limited Growth: Without understanding parents' needs, your school may miss out on admissions and revenue.
                            <br />
                            &gt; Decreased Brand Loyalty: Unresolved issues and unhappy parents can make them look for other options, hurting your school's reputation.
                            <br />
                            &gt; Financial Loss: Fewer admissions due to unhappy parents mean less money and slow growth for your school.
                        </>
                    </Heading>
                </div>
            </div>


            {/* AI-Powered ERP Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 container-xs sm:w-[90%]">
                <div className="text-center container-xs flex flex-col items-center gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            The Solution
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex gap-12 w-[75%] sm:block sm:w-[95%]">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {data1.map((d, index) => (
                                <FlipCard key={"featuresList1" + index} width="100%" height="300px" className="sm:mt-4">
                                    <FlipCard.Front>
                                        <div className="flex w-full flex-col items-center rounded bg-white-A700 py-6 gap-8">
                                            <Img src={d.appsImage} alt="apps image" className="h-[150px] w-[49%]" />
                                            <div className="text-center">
                                                <Text size="s" as="p" className="font-light !text-black-900">
                                                    {d.appsDescription}
                                                </Text>
                                            </div>
                                        </div>
                                    </FlipCard.Front>
                                    <FlipCard.Back className="p-4">
                                        {d.des}
                                    </FlipCard.Back>
                                </FlipCard>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* live demo cta section */}
            <div className="flex justify-center">
                <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl sm:p-5">
                    <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                        Ready to Transform Your Communication?
                    </Heading>
                    <Button
                        size="lg"
                        shape="round"
                        color="deep_purple_400_red_500"
                        className="min-w-[392px] font-extrabold sm:px-5 sm:min-w-[292px] sm:h-[76px]"
                        onClick={handleModal}
                    >
                        Click here for a LIVE demo
                    </Button>
                </div>
            </div>

            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Happy Parents = Brand Ambassadors
                        </Heading>
                    </div>
                    {/* Action for Educationl Institutions */}
                    <div className="md:flex justify-evenly hidden items-center sm:block sm:w-[100%] sm:m-auto md:flex-wrap">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {[{ title: "Word of Mouth", des: "Happy parents talk about your school, bringing in more admissions." },
                            { title: "Positive Reputation", des: "Good stories about your school make more people want to join." },
                            { title: "Growth Formula", des: "Happy parents mean more admissions and a better reputation for your school." }
                            ].map((d, index) => (
                                <div key={"featuresList12" + index}
                                    className="cursor-pointer relative md:w-[40%] sm:w-[100%]">
                                    <div className=" p-5 h-[300px] rounded-[15px] border-2 border-white bg-[#8450E7] flex flex-col gap-[1rem] justify-between my-5">
                                        <div className="space-y-4">
                                            <p className="text-[white] font-bold text-xl">{d.des}</p>
                                        </div>
                                        <div className="space-y-4">
                                            <p className="text-[white]">{d.des}</p>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Suspense>
                    </div>
                    <div className="grid grid-cols-12 justify-center items-center rounded-lg bg-gray-100_01 px-4 mb-4 gap-10 mx-[150px] md:hidden">
                        <div className="col-span-12 text-left">
                            <SlidingCard
                                data={[
                                    { title: "Word of Mouth", des: "Happy parents talk about your school, bringing in more admissions." },
                                    { title: "Positive Reputation", des: "Good stories about your school make more people want to join." },
                                    { title: "Growth Formula", des: "Happy parents mean more admissions and a better reputation for your school." }
                                ]}
                            />
                        </div>
                        {/* <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">Word of Mouth</p>
                            <p className="text-sm text-[white]"> Happy parents talk about your school, bringing in more admissions.</p>
                        </div>
                        <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">Positive Reputation</p>
                            <p className="text-sm text-[white]">Good stories about your school make more people want to join.</p>
                        </div>
                        <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">Growth Formula</p>
                            <p className="text-sm text-[white]">Happy parents mean more admissions and a better reputation for your school.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* faq section */}
            <FaqSection data={FAQDATA} height="h-[460px]" />

            {/* footer section */}
            <Footer />
        </div>
    );
}

export default CustomerSatisfaction;

const FAQDATA = [
    {
        question: "Why should we bother with Parental Insight when our current system is working fine?",
        answers: [
            {
                question: "Why should we bother with Parental Insight when                our current system is working fine?",
                answer: [{
                    bold: "",
                    answer: "Even if things seem fine, there's always room for                    improvement. Parental Insight helps you understand what                    parents really think, allowing you to address issues before                    they become big problems. It ensures you stay ahead and                    keep improving."
                }
                ]
            }
        ]
    }, {
        question: "How do we know the feedback will be honest and        useful?",
        answers: [
            {
                question: "Q. We are concerned about the complexity of migrating our data to a new system ?",
                answer: [{
                    bold: "A:",
                    answer: "Parental Insight uses multiple channels like calls, SMS,                    WhatsApp, and apps to gather feedback, making it                    convenient for parents to share their true thoughts. Honest                    feedback is crucial for making real improvements and                    showing parents you care about their opinions."
                }
                ]
            },
            {
                question: "Q: How will we handle the extra work from gathering and                analyzing feedback?",
                answer: [{
                    bold: "A:",
                    answer: "Parental Insight is designed to be efficient. It streamlines                    the feedback process using automated systems and                    provides actionable insights. This means less manual work                    for your staff and more effective use of the feedback you                    receive"
                }
                ]
            }
        ]
    }, {
        question: "Isn't this just an additional cost with no clear benefit?",
        answers: [
            {
                question: "Isn't this just an additional cost with no clear                benefit?",
                answer: [{
                    bold: "",
                    answer: "Investing in Parental Insight is an investment in your school's                    future. Happy parents are more likely to refer your school to                    others, increasing admissions and revenue. Unhappy parents,                    on the other hand, can lead to a loss of reputation and                    financial strain. The benefits far outweigh the costs."
                }
                ]
            }
        ]
    }, {
        question: "What if parents don't participate in the surveys?",
        answers: [
            {
                question: "What if parents don't participate in the surveys?",
                answer: [{
                    bold: "",
                    answer: "By using multiple channels to reach out—like calls, SMS,                    WhatsApp, and apps-we make it easy for parents to                    participate. Regular communication and showing parents that                    their feedback leads to real changes will encourage more                    participation over time."
                }
                ]
            }
        ]
    }, {
        question: "Why should we bother with Parental Insight when our current system is working fine?",
        answers: [
            {
                question: "Why should we bother with Parental Insight when                our current system is working fine?",
                answer: [{
                    bold: "",
                    answer: "While face-to-face meetings are important, they can be time-                    consuming and may not always provide the full picture.                    Parental Insight offers a systematic approach to gather                    consistent feedback from all parents, ensuring no concerns                    are overlooked."
                }
                ]
            }
        ]
    }
]