import HeroTitle from "components/HeroTitle";
import { Heading, Img, Button, Text } from "../../components";
import Footer from "../../components/Footer";
import React, { Suspense } from "react";
import { Link } from 'react-router-dom';
import SlidingCard from "components/SlidingCard";
import FaqSection from "components/FaqSection";
import { Link as AnchorTag } from 'react-scroll';
import AboutSection from "components/AboutSection";
import InboundButton from "components/InboundButton";

const data = [
    { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
    { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
    { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
    { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
    { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

const socialSuccessHub = [
    { appsImage: "images/social_hub1.jpeg", appsDescription: "Reach upto 100,000 potential parents with the help of Social Hub.", title: "ParentFilled Reach Formula", color: "text-[#51095C]" },
    { appsImage: "images/social_hub2.jpeg", appsDescription: "The more people see your school's name the more inquiries you get.", title: "Name Recognition", color: "text-[#51095C]" },
    { appsImage: "images/social_hub3.jpeg", appsDescription: "Increased inquiries directly lead to increased admissions.", title: "Boosted Admissions", color: "text-[#51095C]" },
];

const DigitalMarketing = ({ handleModal }) => {
    return (
        <div className="flex flex-col gap-8">
            {/* navigation bar section */}

            {/* hero section */}
            <div className="flex flex-col gap-[50px]">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 md:items-end">
                        <Button
                            size="xs"
                            variant="fill"
                            className="min-w-[250px] hidden md:block rounded-bl-[10px] rounded-tl-[10px] font-extrabold sm:px-5"
                        >
                            Patent Filed
                        </Button>
                    </div>

                    <div className="flex flex-row relative">
                        <div className="container-xs md:p-5 md:px-5">
                            <div className="flex flex-col items-center gap-1 sm:text-center">
                                <Heading size="8xl" as="h1" className="!font-poppins !text-black-900_b2">
                                    India’s First Business Ecosystem for{" "}
                                </Heading>
                                <HeroTitle />
                            </div>
                        </div>
                        <Button
                            size="xs"
                            variant="fill"
                            className="absolute top-2 right-0 min-w-[250px] rounded-bl-[10px] rounded-tl-[10px] font-extrabold md:hidden"
                        >
                            Patent Filed
                        </Button>
                    </div>
                    <div className="relative self-stretch">
                        <div className="flex w-full flex-col">
                            <div className="relative z-[1] flex flex-col items-center">
                                <div className="grid grid-cols-5 px-2 h-[9.3vh] md:h-[7.3vh] gap-12 sm:h-auto sm:gap-2 sm:px-4">
                                    <Link to={"/"}>
                                        <img src="/images/img_a1.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/digital-marketing"}>
                                        <img src="/images/img_a2_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/customer-satisfaction"}>
                                        <img src="/images/img_a3.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/fee-collection-process"}>
                                        <img src="/images/img_a4.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/ai-powered-managment-hub"}>
                                        <img src="/images/img_a5.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                </div>
                                <div className="absolute container-xs top-[85px] left-[48%] sm:hidden">
                                    <Img src="images/next-arrow.gif" alt="arrow image" className="h-[30px] rotate-90" />
                                </div>
                                <div className="container-xs relative z-[-1] pl-[230px]  mt-[8px] sm:pl-[70px]  md:pl-[150px] sm:m-0">
                                    <Img src="images/img_arrow_2.svg" alt="arrow image" className="h-[130px] sm:h-[100px] md:h-[150px]" />
                                </div>
                            </div>
                            <div className="container-xs relative pl-[35px] pr-9 md:p-5 sm:px-5 sm:py-0">
                                <div className="flex items-center justify-between items-stretch gap-5 md:flex-col">
                                    <div className=" py-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[39%] flex-col items-center gap-3 rounded-tl-[52px] rounded-br-[52px] border border-solid bg-gradient5  md:w-full sm:p-5">
                                        <Heading
                                            size="s"
                                            as="h3"
                                            className="w-[95%] text-center !font-semibold leading-[22px] !text-black-900 md:w-full"
                                        >
                                            Boost Admissions Up To 30% or gain 2-5 Lakhs with Social Hub by reaching up to 1,00,000 Families.
                                        </Heading>
                                        <div className="flex w-[92%] justify-between gap-5 md:w-full sm:flex-col sm:items-center">
                                            <Button
                                                color="white_A700_01"
                                                size="xl"
                                                variant="fill"
                                                shape="round"
                                                className="min-w-[160px] font-medium"
                                            // onClick={handleModal}
                                            >
                                                Book a Demo
                                            </Button>
                                            <AnchorTag
                                                activeClass="active"
                                                to={"AUTO_CONNECT"}
                                                spy={true}
                                                smooth={true}
                                                offset={-120}
                                                duration={1000}
                                            >
                                                <Button
                                                    size="xl"
                                                    variant="fill"
                                                    shape="round"
                                                    className="min-w-[160px] font-medium shadow-sm sm:px-5"
                                                >
                                                    Know More
                                                </Button>
                                            </AnchorTag>
                                        </div>
                                    </div>
                                    <div className="pb-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[35%] justify-center rounded-tl-[52px] rounded-br-[52px] bg-gradient5 md:w-full">
                                        <div className="flex w-full flex-col items-center gap-[9px] px-6">
                                            <Heading
                                                size="s"
                                                as="h4"
                                                className="flex items-center justify-center rounded-bl-[10px] rounded-br-[10px] bg-purple-900 px-[22px] py-[3px] !font-reemkufi sm:px-5"
                                            >
                                                POTENTIAL EARNINGS
                                            </Heading>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Earning minimum:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>2,50,000
                                                </Text>
                                            </div>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Total Earnings:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>4,00,000
                                                </Text>
                                            </div>
                                            <Heading size="md" as="h6" className="!font-semibold !text-red-A700_99">
                                                *Above data is subject to Terms and Conditions.
                                            </Heading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 right-0 top-[37%] z-[2] m-auto flex w-[35%] items-center justify-center gap-2 rounded-md border border-solid border-black-900 bg-gray-100_02 sm:relative sm:hidden md:top-[15%] md:w-[60%]">
                            <Button
                                size="md"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[29%] rounded-bl-md rounded-br-2xl rounded-tl-md rounded-tr-2xl !bg-gradient font-poppins font-bold"
                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="w-[74%] text-center leading-[23px] !text-black-900 sm:w-full sm:p-5">
                                <>
                                    Save at least 1 lakh per year by eliminating
                                    the need for a separate team to manage it.
                                </>
                            </Text>
                        </div>
                        <div className="absolute left-[10%] right-0 top-[8%] z-[2] m-auto hidden sm:flex items-center justify-center rounded-md border border-solid border-black-900 bg-gray-100_02 sm:w-[85%]">
                            <Button
                                size="xl"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[20%] sm:p-0 rounded-bl-md rounded-br-xl rounded-tl-md rounded-tr-xl !bg-gradient font-poppins font-bold sm:h-[40px] sm:text-xs"                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="text-center leading-[18px] !text-black-900 sm:w-full px-1 sm:text-xs">
                                <>
                                    Save at least 1 lakh per year by eliminating
                                    the need for a separate team to manage it.
                                </>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xs my-2">
                <Img
                    src="images/img_mask_group.png"
                    alt="masked image"
                    className="h-[10px] w-full rounded-md object-cover"
                />
            </div>

            {/* about section */}
            <AboutSection />

            {/* features section */}
            <div id={"AUTO_CONNECT"} className="mt-[50px] flex flex-col bg-gray-100_01 pb-[50px] pt-[45px] md:py-5">
                <div className="container-xs flex flex-col items-center gap-[47px] pl-[37px] pr-[34px] md:p-5 sm:px-5">
                    <div className="flex flex-col items-start gap-10 self-stretch">
                        <div className="flex w-[95%] items-center gap-[41px] md:w-full md:flex-col sm:flex-col-reverse">
                            <Img
                                src="images/img_group_151x150_2.svg"
                                alt="logo image"
                                className="h-[151px] w-[150px] object-cover md:w-full md:object-contain	sm:m-auto"
                            />
                            <Heading
                                size="8xl"
                                as="h2"
                                className="w-[86%] bg-gradient2 bg-clip-text text-center !font-lexenddeca leading-[50px] !text-transparent md:w-full sm:leading-[40px]"
                            >
                                <span className="bg-gradient2 bg-clip-text text-transparent">
                                    SOCIAL HUB : Boost Your <br /> Educational institution Visibility
                                </span>
                            </Heading>
                        </div>
                        <Heading
                            size="9xl"
                            as="h3"
                            className="ml-3 bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent md:ml-0"
                        >
                            <span className="bg-gradient2 bg-clip-text text-transparent">Why Choose&nbsp;</span>
                            <span className="bg-gradient2 bg-clip-text text-[43px] text-transparent sm:text-[38px]">SOCIAL HUB ?</span>
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="ml-[27px] w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-gray-900_01 md:ml-0"
                        >
                            <>
                                Expand your outreach effortlessly, connecting with 100,000 families and boosting admissions through enhanced visibility and digital marketing. Tailor a dynamic website, integrate a chatbot for instant support, and maintain uninterrupted online presence with reliable hosting and regular upgrades. Elevate Google visibility with ongoing SEO, attracting more prospective students and parents.
                            </>
                        </Heading>
                    </div>
                    <div className="flex w-[92%] flex-col items-center justify-center gap-11 rounded-[40px] bg-deep_purple-100 px-14 pb-[166px] pt-[139px] md:w-full md:p-5">
                        <Button size="5xl" shape="square" className="w-[100px]">
                            <Img src="images/img_ellipse_557.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.94px] !text-deep_purple-700">
                            VIDEO COMING SOON
                        </Heading>
                    </div>
                    <InboundButton />
                    {/* <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Ready to Transform Your Communication?
                        </Heading>
                        <Button
                            size="lg"
                            shape="round"
                            color="deep_purple_400_red_500"
                            className="min-w-[392px] font-extrabold sm:px-5"
                            onClick={handleModal}
                        >
                            Click here for DEMO
                        </Button>
                    </div> */}
                </div>
            </div>

            {/* benefits section */}
            <div className="laptop:container-xs relative grid grid-cols-2 justify-center gap-[9px] px-[25px] md:grid-cols-1 sm:px-5">
                <div className="flex w-full flex-col items-center justify-center rounded-full bg-gradient3 border-[10px] border-[white] hidden sm:block text-center">
                    <Heading size="8xl" as="h6" className="!font-semibold">
                        Showcase on <br />Social Hub
                    </Heading>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#552AA1] p-8 hover:shadow-[15px_35px_60px_2px_#552AA1]">
                    <div className="flex w-full flex-col items-start gap-4">
                        <Button color="purple_A200_01" size="2xl" variant="fill" shape="round" className="w-[61px] !rounded-lg">
                            <Img src="images/img_close.svg" />
                        </Button>
                        <Heading as="h2" className="!text-[21.94px]">
                            Academic Achievements
                        </Heading>
                        <Heading size="s" as="h3" className="w-full !text-[15.75px] leading-6">
                            <>
                                Showcase your school's academic excellence by sharing pass percentages, ranks in competitive exams, and achievements in academic competitions.
                            </>
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#B41AB1] p-8 hover:shadow-[15px_35px_60px_2px_#B41AB1]">
                    <div className="flex w-full flex-col items-end gap-4">
                        <Button
                            color="purple_A200_02"
                            size="4xl"
                            variant="fill"
                            shape="round"
                            className="w-[61px] !rounded-lg md:mr-0"
                        >
                            <Img src="images/social_hub_feature_2.svg" />
                        </Button>
                        <Heading as="h4" className="!font-inter !text-[21.94px] !font-bold">
                            Extracurricular Activities
                        </Heading>
                        <Heading size="s" as="h5" className="w-full text-right !text-[15.75px] leading-6">
                            Highlight the vibrant extracurricular life of your school by showcasing <br />
                            events such as sports days, cultural festivals, Independence Day <br />
                            celebrations, science fairs, debates, and arts exhibitions.
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#B41AB1] p-8 hover:shadow-[15px_35px_60px_2px_#B41AB1]">
                    <div className="flex w-full flex-col items-start gap-4">
                        <Button color="purple_A200_02" size="4xl" variant="fill" shape="round" className="w-[64px] !rounded-lg">
                            <Img src="images/social_hub_feature_3.svg" />
                        </Button>
                        <Heading size="4xl" as="h5" className="!text-[22.13px] !font-bold">
                            Parent Testimonials
                        </Heading>
                        <Heading size="s" as="p" className="w-full !text-[15.63px] leading-6">
                            <>
                                Build trust and credibility by featuring testimonials from satisfied parents <br />
                                who have experienced the benefits of your school firsthand.
                            </>
                        </Heading>
                    </div>
                </div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:hidden">
                    <div className="flex w-full flex-col items-center justify-center rounded-full bg-gradient3 py-11 px-10 border-[10px] border-[white]">
                        <Heading size="5xl" as="h6" className="!font-semibold text-center">
                            Showcase <br /> on Social <br /> Hub
                        </Heading>
                    </div>
                </div>
                <div className="flex h-[320px] w-full justify-center rounded-2xl bg-[#552AA1] p-8 hover:shadow-[15px_35px_60px_2px_#552AA1]">
                    <div className="flex w-full flex-col items-end gap-4">
                        <Button color="purple_A200_01" size="2xl" variant="fill" shape="round" className="w-[59px] !rounded-lg">
                            <Img src="images/social_hub_feature_4.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.56px] !font-bold md:mr-0">
                            Engaging Content
                        </Heading>
                        <Heading size="s" as="p" className="w-full text-right !font-inter !text-[15.63px] leading-6">
                            <>
                                Create engaging and shareable content to captivate your <br />
                                audience and promote your school's unique identity. <br />
                                Use posts, videos, reels, and brochures.
                            </>
                        </Heading>
                    </div>
                </div>
            </div>

            {/* Action for Educationl Institutions */}
            <div className="laptop:container-xs grid grid-cols-12 items-center rounded-lg bg-gray-100_01 px-4 mx-[25px] pb-8 gap-5 min-h-[400px]">
                <div className="col-span-3 text-center md:col-span-12">
                    <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                        Action for <br /> Educational <br /> Institutions
                    </Heading>
                </div>
                <div className="md:flex justify-evenly hidden items-center sm:block sm:w-[80%] sm:m-auto md:col-span-12 ">
                    <Suspense fallback={<div>Loading feed...</div>}>
                        {[{ title: "Go Digital", des: "Embrace the power of digital marketing with Social Hub." },
                        { title: "Stay Competitive", des: "Ensure your school stands out among competitors." },
                        { title: "Smart Promotion", des: "Utilize Social Hub to showcase your school's strengths and entice more" }
                        ].map((d, index) => (
                            <div key={"featuresList12" + index} className="cursor-pointer relative md:w-[40%] sm:w-[100%]">
                                <div className=" p-5 h-[300px] rounded-[15px] border-2 border-white bg-[#8450E7] flex flex-col gap-[1rem] justify-between my-5">
                                    <div className="space-y-4">
                                        <p className="text-[white] font-bold text-xl">{d.des}</p>
                                    </div>
                                    <div className="space-y-4">
                                        <p className="text-[white]">{d.des}</p>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </Suspense>
                </div>
                <div className="col-span-9 md:hidden">
                    <SlidingCard
                        data={[
                            { title: "Go Digital", des: "Embrace the power of digital marketing with Social Hub." },
                            { title: "Stay Competitive", des: "Ensure your school stands out among competitors." },
                            { title: "Smart Promotion", des: "Utilize Social Hub to showcase your school's strengths and entice more" }
                        ]}
                    />
                    {/* <ul className="cards_SOOMUCH_SLIDING_CONTAINER">
                        <li className="card_SOOMUCH_SLIDING_CARD card-1_SOOMUCH">
                            <div className="content_SOOMUCH_SLIDING_CONTENT">
                                <h1>Card 1 Title</h1>
                                <p>Card description</p>
                            </div>
                        </li>
                        <li className="card_SOOMUCH_SLIDING_CARD card-2_SOOMUCH">
                            <div className="content_SOOMUCH_SLIDING_CONTENT">
                                <h1>Card 2 Title</h1>
                                <p>Card description</p>
                            </div>
                        </li>
                        <li className="card_SOOMUCH_SLIDING_CARD card-3_SOOMUCH">
                            <div className="content_SOOMUCH_SLIDING_CONTENT">
                                <h1>Card 3 Title</h1>
                                <p>Card description</p>
                            </div>
                        </li>
                    </ul> */}
                </div>
            </div>
            {/* <div className="laptop:container-xs grid grid-cols-12 items-center rounded-lg bg-gray-100_01 px-4 py-8 mx-[25px] gap-5">
                <div className="col-span-3 text-center">
                    <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                        Action for <br /> Educational <br /> Institutions
                    </Heading>
                </div>
                <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] w-[70%] text-center gap-5">
                    <p className="text-[white] font-bold text-3xl">Go Digital</p>
                    <p className="text-sm text-[white]"> Embrace the power of digital marketing with Social Hub.</p>
                </div>
                <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] w-[70%] text-center gap-5">
                    <p className="text-[white] font-bold text-3xl">Stay Competitive</p>
                    <p className="text-sm text-[white]">Ensure your school stands out among competitors.</p>
                </div>
                <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] w-[70%] text-center gap-5">
                    <p className="text-[white] font-bold text-3xl">Smart Promotion</p>
                    <p className="text-sm text-[white]">Utilize Social Hub to showcase your school's strengths and entice more</p>
                </div>
            </div> */}

            {/* risks without product section */}
            <div className="container-xs relative rounded-[40px] bg-gray-100_01 py-8 px-4 sm:p-3 sm:mx-[5%] sm:w-[90%]">
                <div className="m-auto flex h-max w-[94%] flex-col items-start">
                    <div className="flex w-[88%] items-center gap-8 md:w-full md:flex-col">
                        <Img src="images/img_group_black_900.svg" alt="group image" className="h-[60px] w-[7%] md:w-full" />
                        <Heading size="8xl" as="h2" className="self-end !font-lexenddeca !text-deep_orange-A400">
                            What You Stand to Lose without SOCIAL HUB
                        </Heading>
                    </div>
                    <Heading size="8xl" as="h3" className="mt-[60px] !font-lexenddeca !text-red-900 sm:mt-[30px]">
                        If you don't opt for SOCIAL HUB, you risk:
                    </Heading>
                    <Heading
                        size="8xl"
                        as="h4"
                        className="w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-black-900"
                    >
                        <>
                            &gt; Limited Visibility: Miss out on reaching 1 lakh potential families.
                            <br />
                            &gt; Fewer Inquiries: Less visibility means fewer inquiries and fewer admissions.
                            <br />
                            &gt; Weak Communication: Without effective digital marketing, your school's name and achievements remain unknown to many
                        </>
                    </Heading>
                </div>
            </div>

            <div className="flex justify-center">
                <Button
                    size="lg"
                    shape="round"
                    color="deep_purple_400_red_500"
                    className="w-[60%] font-extrabold md:px-5 md:h-[100px] md:w-[90%]"
                    onClick={handleModal}
                >
                    <p>

                        Wanna Apply the Success Formula, Click here for{" "} <span className="underline">{" "}Book a demo</span>
                    </p>
                </Button>
            </div>

            {/* <div className="laptop:container-xs flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col items-center gap-[66px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Success Formula with Social Hub
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly w-full">
                        <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12">
                            <div className="max-w-[60%] h-[270px] social_success_data_cards_SOOMUCH">
                                <div className="h-[99%] px-[40px] social_success_data_cards_Inner_SOOMUCH">
                                    <Heading size="2xl" as="h4" className="!font-poppins">
                                        Offer Discounts and Referral Bonuses
                                    </Heading>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12">
                            <div className="max-w-[60%] h-[270px] social_success_data_cards_SOOMUCH">
                                <div className="h-[99%] px-[50px] social_success_data_cards_Inner_SOOMUCH">
                                    <Heading size="2xl" as="h4" className="!font-poppins">
                                        Regular Updates
                                    </Heading>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="laptop:container-xs flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col items-center gap-[66px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Success Formula with Social Hub
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly w-full sm:block">
                        <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12 sm:w-[100%]">
                            <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                                <img src="images/social_success_top_right_2.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                                <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                                    <div className="social_success_text_show_none_SOOMUCH space-y-2">
                                        <p className="text-md text-[white] font-normal !font-poppins">Offer Discounts and <br /> Referral Bonuses</p>
                                        <p className="text-sm text-[white] font-light text-gray-300">
                                            By providing attractive discounts or referral bonuses for current families who bring in new students.
                                        </p>
                                    </div>
                                    <Heading size="2xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                        Offer Discounts and Referral Bonuses
                                    </Heading>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center bg-gray-100_01 w-[50%] p-8 gap-12 sm:w-[100%]">
                            <div className="relative max-w-[60%] min-w-[60%] h-[270px] social_success_data_cards_SOOMUCH sm:max-w-[100%] sm:min-w-[100%]">
                                <img src="images/social_success_top_right_3.jpeg" alt="" className="social_success_img_show_none_SOOMUCH" />
                                <div className="h-[99%] w-full social_success_data_cards_Inner_SOOMUCH">
                                    <div className="social_success_text_show_none_SOOMUCH space-y-2 mt-4">
                                        <p className="text-md text-[white] font-normal !font-poppins">Regular Updates</p>
                                        <p className="text-sm text-[white] font-light text-gray-300">
                                            Keep potential parents informed about your school's activities and successes.
                                        </p>
                                    </div>
                                    <Heading size="2xl" as="h4" className="social_success_text_title_show_none_SOOMUCH !font-poppins">
                                        Regular Updates
                                    </Heading>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Social Success Hub */}
            {/* <div className="laptop:container-xs flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="container-xs flex flex-col items-center gap-[66px] pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Success Formula with Social Hub
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-center w-[80%] gap-[100px]">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {socialSuccessHub.map((d, index) => (
                                <div
                                    key={"featuresList1" + index}
                                    className="flex w-[120px] hover:w-[45%] hover:text-center flex-col hover:items-center rounded-xl bg-white-A700 border-2 border-white-A700 shadow"
                                >
                                    <Img src={d.appsImage} alt="apps image" className="h-[200px] w-[100%] object-cover rounded-xl" />
                                    <div className="space-y-2 p-1">
                                        <p className={`text-sm font-bold truncate text-ellipsis ${d.color}`}>{d.title}</p>
                                        <p className={`text-xs  truncate text-ellipsis ${d.color}`}>{d.appsDescription}</p>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div> */}
            <div className="laptop:container-xs flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs space-y-8 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Success Formula with Social Hub
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="grid grid-cols-3 gap-[50px] sm:block ">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {socialSuccessHub.map((d, index) => (
                                <div className="flex w-full" key={"featuresList1" + index}>
                                    <div
                                        // key={"featuresList1" + index}
                                        className="social_data_cards_SOOMUCH cursor-pointer h-[280px] flex flex-col items-center rounded-xl bg-white-A700 border-2 border-white-A700 shadow"
                                    >
                                        <Img src={d.appsImage} alt="apps image" className="h-[200px] w-full object-cover rounded-xl" />
                                        <div className="text-center space-y-2 p-1">
                                            <p className={`text-sm font-bold ${d.color}`}>{d.title}</p>
                                            <p className={`text_show_none_SOOMUCH text-xs ${d.color} md:block`}>{d.appsDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>
            <div className="mt-8 flex justify-center">
                <Button
                    size="lg"
                    shape="round"
                    color="deep_purple_400_red_500"
                    className="w-[60%] font-extrabold md:p-5 md:h-[100%] md:w-[90%] "
                    onClick={handleModal}
                >
                    <p>
                        Wanna Apply the Success Formula, Click here for{" "}  <span className="underline">{" "}Book a demo</span>
                    </p>
                </Button>
            </div>

            {/* faq section */}
            <FaqSection data={FAQDATA} height="h-[430px]" />

            {/* footer section */}
            <Footer />
        </div>
    );
}

export default DigitalMarketing;

const FAQDATA = [
    {
        question: "Choose Social Hub",
        answers: [
            {
                question: "Q: Why should we invest in Social Hub?",
                answer: [{
                    bold: "A:",
                    answer: "Social Hub offers a wider reach, connecting your institution with up to 1                    lakh families, increasing admissions significantly."
                }
                ]
            },
            {
                question: "Q: Is digital marketing really necessary for our educational institution?                ",
                answer: [{
                    bold: "A:",
                    answer: "Absolutely. Digital marketing through Social Hub helps promote your                    school on popular platforms like Facebook, Instagram, YouTube, WhatsApp,                    Google, and IVRS, ensuring your institution's visibility reaches its peak."
                }
                ]
            },
            {
                question: "Q: What's the benefit of a dynamic website design and chatbot                integration?",
                answer: [{
                    bold: "A:",
                    answer: "A dynamic website, along with a chatbot, provides instant responses and                    support, enhancing user experience and engagement."
                }
                ]
            },
            {
                question: "Q: Why is regular website hosting and upgrades important?",
                answer: [{
                    bold: "A:",
                    answer: "Website hosting ensures your website is always online, while regular                    upgrades keep it current and functional, ensuring a smooth user experience."
                }
                ]
            },
        ]
    }, {
        question: "What to Showcase on Social Hub",
        answers: [
            {
                question: "Q. Why should we bother showcasing academic                achievements and extracurricular activities?",
                answer: [{
                    bold: "A:",
                    answer: "Showcasing pass percentages, ranks, and medals along                    with extracurricular activities builds trust and credibility                    among potential parents, ultimately leading to increased                    admissions."
                }
                ]
            },
            {
                question: "Q: Are parent testimonials really necessary?",
                answer: [{
                    bold: "A:",
                    answer: "Absolutely. Parent testimonials help build trust and                    confidence among prospective parents, showcasing the                    positive experiences of existing ones."
                }
                ]
            }
        ]
    }, {
        question: "What You Lose Without Social Hub",
        answers: [
            {
                question: "Q. What if we don't invest in Social Hub?",
                answer: [{
                    bold: "A.",
                    answer: "Without Social Hub, your institution risks limited visibility,                    fewer inquiries, and weaker communication, resulting in                    missed opportunities for growth and admissions"
                }
                ]
            }
        ]
    }, {
        question: "Success Formula with Social Hub",
        answers: [
            {
                question: "Q. How can Social Hub ensure success for our institution?",
                answer: [{
                    bold: "A.",
                    answer: "With Social Hub, you can reach up to 100,000 potential                    parents, boosting name recognition and admissions through                    increased inquiries."
                }
                ]
            }
        ]
    }, {
        question: "Action for Educational Institutions",
        answers: [
            {
                question: "Q. Why should we go digital with Social Hub?",
                answer: [{
                    bold: "A.",
                    answer: "Going digital with Social Hub ensures your institution stays                    competitive, effectively promoting your strengths and                    attracting more parents."
                }
                ]
            },
            {
                question: "Q. How can we get started with Social Hub?",
                answer: [{
                    bold: "A.",
                    answer: `Simply click on "Boost My School's Visibility" to get started, or schedule a demo or contact us for more information.`
                }
                ]
            }
        ]
    }
]