import { Button } from "components/Button";
import { Heading } from "components/Heading";
import AfterSubmitionModal from "components/Modal/AfterSubmitionModal";
import InboundModal from "components/Modal/InboundModal";
import React from "react";

const customStylesRefer = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '55rem',
      maxWidth:'90%',
      minHeight: '6rem',
      borderRadius: '1em',
      boxShadow: '0px 16px 32px 4px rgba(0, 0, 0, 0.15)',
      padding: '0px',
      border: '0px'
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.4)',
      zIndex: '999',
    },
  }

const InboundButton = () => {
    const [formSuccessModal, setFormSuccessModal] = React.useState<{ display: boolean; ID: number | undefined; }>({ display: false, ID: undefined });
    const handleModal = () => {
        setFormSuccessModal({ display: true, ID: 0 });
      }
    return (
        <div className="flex w-[82%] flex-col items-center gap-4 rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
            {formSuccessModal?.display && <InboundModal customStyles={customStylesRefer} showModal={formSuccessModal} setshowModal={e => setFormSuccessModal(e)} />}
            <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
            Inbound Comprehensive Communication?
            </Heading>
            <Button
                size="lg"
                shape="round"
                color="deep_purple_400_red_500"
                className="min-w-[392px] font-extrabold sm:px-5 sm:h-auto sm:min-w-[292px]"
                onClick={handleModal}
            >
                Click here for a LIVE demo
            </Button>
        </div>
    );
}

export default InboundButton;