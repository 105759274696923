import { Helmet } from "react-helmet";
import { Heading, Img, Button, Text } from "../../components";
import Faq from "../../components/Faq";
import CommunicationSection from "../../components/CommunicationSection";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom';
import HeroTitle from "components/HeroTitle";
import SlidingCard from "components/SlidingCard";
import FaqSection from "components/FaqSection";
import { Link as AnchorTag } from 'react-scroll';
import InboundButton from "components/InboundButton";

const data = [
    { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
    { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
    { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
    { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
    { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

const data1 = [
    { appsImage: "images/3_mobile_app.svg", appsDescription: "3 Mobile Apps", des: "" },
    { appsImage: "images/5_min_mastery.svg", appsDescription: "5-Minute Mastery", des: "" },
    { appsImage: "images/1_free_customization.svg", appsDescription: "1 Free Customization", des: "" },
    { appsImage: "images/inbuild_virtual_assistance.svg", appsDescription: "INBUILT Virtual Assistants", des: "" },
];

const mobileSection = [
    { appsImage: "images/mobile_super_admin.svg", appsDescription: "Super Admin App", des: "Manage all branches in one app" },
    { appsImage: "images/parent_app.svg", appsDescription: "Parent App", des: "Simplify homework and fee payment processes" },
    { appsImage: "images/faculty_app.svg", appsDescription: "Faculty App", des: "Assign homework, create papers, track tasks." },
];

const unlockSoomuchAISection = [
    { appsImage: "images/rapid_learning.svg", appsDescription: "Rapid Learning", des: "Master the platform in just 5 minutes" },
    { appsImage: "images/effortless_operations.svg", appsDescription: "Effortless Operation", des: "Operate features with just 3 clicks." },
    { appsImage: "images/intelligent_search.svg", appsDescription: "Intelligent Search", des: "Access information quickly." },
];

const tailoredSection = [
    { appsImage: "images/flexiblity.svg", appsDescription: "Flexibility", des: "Adapt to unique institutional needs" },
    { appsImage: "images/complementry_customization.svg", appsDescription: "Complimentary Customization", des: "One free customization for fee and report cards." },
    { appsImage: "images/seamless_fit.svg", appsDescription: "Seamless Fit", des: "Customize to ensure a perfect fit for your institution." },
];

const revolutionizingSection = [
    { appsImage: "images/instance_assistance.svg", appsDescription: "Instant Assistance", des: "INBUILT Virtual Assistants provide immediate support." },
    { appsImage: "images/effeciency_unleashed.svg", appsDescription: "Efficiency Unleashed", des: "Swift issue resolution for smooth operations." },
    { appsImage: "images/accessable_in_30_sec.svg", appsDescription: "Accessible in 30 Seconds", des: "Quick support whenever needed" },
    { appsImage: "images/seamless_user_experience.svg", appsDescription: "Seamless User Experience", des: "Timely assistance for a positive environment" },
];

const revolutionizingSupport = [
    { title: "Intuitive Dashboard", des: "Access comprehensive information at a glance" },
    { title: "Free Data Migration", des: "Smoothly transition with our support" },
    { title: "SMS Template Approval Support", des: "Customize communication easily." },
    { title: "Cost Savings", des: "Save up to 80% on website spending." }
];

const AiPoweredManagmentHub = ({ handleModal }) => {
    return (
        <div className="flex flex-col gap-8">
            {/* navigation bar section */}

            {/* hero section */}
            <div className="flex flex-col gap-[50px]">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 md:items-end">
                        <Button
                            size="xs"
                            variant="fill"
                            className="min-w-[250px] hidden md:block rounded-bl-[10px] rounded-tl-[10px] font-extrabold sm:px-5"
                        >
                            Patent Filed
                        </Button>
                    </div>

                    <div className="flex flex-row relative">
                        <div className="container-xs md:p-5 md:px-5">
                            <div className="flex flex-col items-center gap-1 sm:text-center">
                                <Heading size="8xl" as="h1" className="!font-poppins !text-black-900_b2">
                                    India’s First Business Ecosystem for{" "}
                                </Heading>
                                <HeroTitle />
                            </div>
                        </div>
                        <Button
                            size="xs"
                            variant="fill"
                            className="absolute top-2 right-0 min-w-[250px] rounded-bl-[10px] rounded-tl-[10px] font-extrabold md:hidden"
                        >
                            Patent Filed
                        </Button>
                    </div>
                    <div className="relative self-stretch">
                        <div className="flex w-full flex-col">
                            <div className="relative z-[1] flex flex-col items-center">
                                <div className="grid grid-cols-5 px-2 h-[9.3vh] md:h-[7.3vh] gap-12 sm:h-auto sm:gap-2 sm:px-4">
                                    <Link to={"/"}>
                                        <img src="/images/img_a1.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/digital-marketing"}>
                                        <img src="/images/img_a2_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/customer-satisfaction"}>
                                        <img src="/images/img_a3_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/fee-collection-process"}>
                                        <img src="/images/img_a4_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/ai-powered-managment-hub"}>
                                        <img src="/images/img_a5_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                </div>
                                <div className="container-xs relative z-[-1] mt-[8px] pl-[300px] pr-[0px]  md:hidden sm:pl-[50px] md:pl-[250px]">
                                    <Img src="images/img_arrow_5.svg" alt="arrow image" className="w-full h-[130px] sm:h-[100px] md:h-[150px] sm:w-[80%]" />
                                </div>
                                <div className="hidden container-xs z-[-1] md:block mt-[8px] pl-[300px] pr-[0px]  sm:m-0 sm:pl-[50px] md:pl-[250px]">
                                    <Img src="images/img_arrow_5small.svg" alt="arrow image" className="w-full h-[130px] sm:h-[100px] md:h-[150px] sm:w-[80%]" />
                                </div>
                            </div>
                            <div className="container-xs relative pl-[35px] pr-9 md:p-5 sm:px-5 sm:py-0">
                                <div className="flex items-center justify-between items-stretch gap-5 md:flex-col">
                                    <div className=" py-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[39%] flex-col items-center gap-3 rounded-tl-[52px] rounded-br-[52px] border border-solid bg-gradient5  md:w-full sm:p-5">
                                        <Heading
                                            size="s"
                                            as="h3"
                                            className="w-[95%] text-center !font-semibold leading-[22px] !text-black-900 md:w-full"
                                        >
                                            Experience the power of Our AI-Driven Management Hub ! Save min. 1,00,000 Lakh INR and up to 80% of your time.
                                            Learn in just 5 minutes.
                                        </Heading>
                                        <div className="flex w-[92%] justify-between gap-5 md:w-full sm:flex-col sm:items-center">
                                            <Button
                                                color="white_A700_01"
                                                size="xl"
                                                variant="fill"
                                                shape="round"
                                                className=" min-w-[160px] font-medium"
                                            // onClick={handleModal}
                                            >
                                                Book a Demo
                                            </Button>
                                            <AnchorTag
                                                activeClass="active"
                                                to={"AUTO_CONNECT"}
                                                spy={true}
                                                smooth={true}
                                                offset={-120}
                                                duration={1000}
                                            >
                                                <Button
                                                    size="xl"
                                                    variant="fill"
                                                    shape="round"
                                                    className="min-w-[160px] font-medium shadow-sm sm:px-5"
                                                >
                                                    Know More
                                                </Button>
                                            </AnchorTag>
                                        </div>
                                    </div>
                                    <div className="pb-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[35%] justify-center rounded-tl-[52px] rounded-br-[52px] bg-gradient5 md:w-full">
                                        <div className="flex w-full flex-col items-center gap-[9px] px-6">
                                            <Heading
                                                size="s"
                                                as="h4"
                                                className="flex items-center justify-center rounded-bl-[10px] rounded-br-[10px] bg-purple-900 px-[22px] py-[3px] !font-reemkufi sm:px-5"
                                            >
                                                POTENTIAL EARNINGS
                                            </Heading>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Earning minimum:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>1,00,000
                                                </Text>
                                            </div>
                                            <div className="flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Total Earnings:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>7,00,000
                                                </Text>
                                            </div>
                                            <Heading size="md" as="h6" className="!font-semibold !text-red-A700_99">
                                                *Above data is subject to Terms and Conditions.
                                            </Heading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="absolute left-0 right-0 top-[35%] z-[2] m-auto flex w-[32%] items-center justify-center gap-2 rounded-md border border-solid border-black-900 bg-gray-100_02 sm:relative sm:flex-col">
                            <Button
                                size="3xl"
                                color="deep_purple_400_red_500"
                                className="min-w-[29%] rounded-bl-md rounded-br-2xl rounded-tl-md rounded-tr-2xl !bg-gradient font-poppins font-bold"
                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="w-[71%] text-center leading-[23px] !text-black-900 sm:w-full sm:p-5">
                                <>
                                    To gain 2-5 lakhs with DIGITAL MARKETING <br />
                                    by reaching upto 1,00,000 families.
                                </>
                            </Text>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container-xs my-2">
                <Img
                    src="images/img_mask_group.png"
                    alt="masked image"
                    className="h-[10px] w-full rounded-md object-cover"
                />
            </div>

            {/* about section */}
            <AboutSection />

            {/* features section */}
            <div id={"AUTO_CONNECT"} className="mt-[50px] flex flex-col bg-gray-100_01 pb-[50px] pt-[45px] md:py-5">
                <div className="container-xs flex flex-col items-center gap-[47px] pl-[37px] pr-[34px] md:p-5 sm:px-5">
                    <div className="flex flex-col items-start gap-10 self-stretch">
                        <div className="flex w-[95%] items-center gap-[41px] md:w-full md:flex-col sm:flex-col-reverse">
                            <Img
                                src="images/img_group_151x150_5.svg"
                                alt="logo image"
                                className="h-[151px] w-[150px] object-cover md:w-full md:object-contain	sm:m-auto"
                            />
                            <Heading
                                size="8xl"
                                as="h2"
                                className="w-[86%] bg-gradient2 bg-clip-text text-center !font-lexenddeca leading-[50px] !text-transparent md:w-full sm:leading-[40px]"
                            >
                                <span className="bg-gradient2 bg-clip-text text-transparent">
                                    AI-POWERED MANAGEMENT HUB: <br /> Transforming Educational <br /> Management
                                </span>
                            </Heading>
                        </div>
                        <Heading
                            size="9xl"
                            as="h3"
                            className="ml-3 bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent md:ml-0"
                        >
                            <span className="bg-gradient2 bg-clip-text text-transparent">Why Choose&nbsp;</span>
                            <span className="bg-gradient2 bg-clip-text text-[43px] text-transparent">AI-POWERED MANAGEMENT HUB ?</span>
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="ml-[27px] w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-gray-900_01 md:ml-0"
                        >
                            <>
                                Introducing our AI-powered MANAGEMENT HUB, streamlining day-to-day operations and tasks to enable one employee to accomplish the workload of several, resulting in savings of at least 1,00,000 INR and an 80% reduction in time spent. Maximize cost savings by cut expenses by at least 1,00,000 INR, eliminating the need for multiple employees. Also slash operational time by 80%, boosting overall productivity. Easy to adopt and can be mastered in just 5 minutes, ensuring swift implementation and user-friendly navigation.
                            </>
                        </Heading>
                    </div>
                    <div className="flex w-[92%] flex-col items-center justify-center gap-11 rounded-[40px] bg-deep_purple-100 px-14 pb-[166px] pt-[139px] md:w-full md:p-5">
                        <Button size="5xl" shape="square" className="w-[100px]">
                            <Img src="images/img_ellipse_557.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.94px] !text-deep_purple-700">
                            VIDEO COMING SOON
                        </Heading>
                    </div>
                    <InboundButton />
                    {/* <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Ready to Transform Your Communication?
                        </Heading>
                        <Button
                            size="lg"
                            shape="round"
                            color="deep_purple_400_red_500"
                            className="min-w-[392px] font-extrabold sm:px-5"
                            onClick={handleModal}
                        >
                            Click here for DEMO
                        </Button>
                    </div> */}
                </div>
            </div>

            {/* AI-Powered ERP Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-[66px] pl-[38px] pr-[34px] sm:gap-[26px]">
                    <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent sm:hidden">
                        AI-Powered ERP with Free Data Migration and SMS Template Approval Support
                    </Heading>
                    <Heading size="4xl" as="h6" className="bg-gradient2 hidden bg-clip-text !font-lexenddeca !text-transparent sm:block">
                        AI-Powered ERP with Free Data Migration and SMS Template Approval Support
                    </Heading>
                    <div className="flex gap-[50px] sm:block md:gap-[20px]">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {data1.map((d, index) => (
                                <div
                                    key={"featuresList1" + index}
                                    className="flex w-full flex-col items-center  gap-[26px] rounded-[20px] bg-white-A700 py-6 px-4 sm:mt-5"
                                >
                                    <Img src={d.appsImage} alt="apps image" className="h-[94px] w-[49%]" />
                                    <div className="text-center">
                                        <Text size="xl" as="p" className="text-2xl font-light !text-black-900">
                                            {d.appsDescription}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* Mobile Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            SOOMUCH.AI Mobile Apps
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly items-center sm:block" >
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {mobileSection.map((d, index) => (
                                <div
                                    key={"featuresList12" + index + d.appsDescription}
                                    className="cursor-pointer transition ease-linear delay-200 group hover:bg-deep_purple-A200 relative w-[200px] h-[200px] rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex flex-col items-center justify-center gap-4 sm:mt-5 sm:m-auto"
                                >
                                    <div className="group-hover:hidden absolute h-10 w-10 left-2 top-4 rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex justify-center items-center">
                                        <Text size="md" as="p" className="font-bold !text-black-900">
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Img src={d.appsImage} alt="apps image" className="group-hover:hidden h-[70px] w-[49%]" />
                                    <div className="text-center mx-8">
                                        <Text size="xl" as="p" className="group-hover:hidden text-xl font-light text-deep_purple-A200">
                                            {d.appsDescription}
                                        </Text>
                                        <Text size="xl" as="p" className="hidden group-hover:text-[white] group-hover:block text-xs font-light text-deep_purple-A200">
                                            {d.des}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* Unlock SOOMUCH.AI Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Unlock SOOMUCH.AI in Just 5 Minutes!
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly items-center sm:block">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {unlockSoomuchAISection.map((d, index) => (
                                <div
                                    key={"featuresList12" + index + d.appsDescription}
                                    className="cursor-pointer transition ease-linear delay-200 group hover:bg-deep_purple-A200 relative w-[200px] h-[200px] rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex flex-col items-center justify-center gap-4 sm:mt-5 sm:m-auto"
                                >
                                    <div className="group-hover:hidden absolute h-10 w-10 left-2 top-4 rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex justify-center items-center">
                                        <Text size="md" as="p" className="font-bold !text-black-900">
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Img src={d.appsImage} alt="apps image" className="group-hover:hidden h-[70px] w-[49%]" />
                                    <div className="text-center mx-8">
                                        <Text size="xl" as="p" className="group-hover:hidden text-xl font-light text-deep_purple-A200">
                                            {d.appsDescription}
                                        </Text>
                                        <Text size="xl" as="p" className="hidden group-hover:text-[white] group-hover:block text-xs font-light text-deep_purple-A200">
                                            {d.des}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* Tailored Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Tailored to Your Needs: 1 Free Customization!
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly items-center sm:block">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {tailoredSection.map((d, index) => (
                                <div
                                    key={"featuresList12" + index + d.appsDescription}
                                    className="cursor-pointer transition ease-linear delay-200 group hover:bg-deep_purple-A200 relative w-[200px] h-[200px] rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex flex-col items-center justify-center gap-4 sm:mt-5 sm:m-auto"
                                >
                                    <div className="group-hover:hidden absolute h-10 w-10 left-2 top-4 rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex justify-center items-center">
                                        <Text size="md" as="p" className="font-bold !text-black-900">
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Img src={d.appsImage} alt="apps image" className="group-hover:hidden h-[70px] w-[49%]" />
                                    <div className="text-center mx-8">
                                        <Text size="xl" as="p" className="group-hover:hidden text-xl font-light text-deep_purple-A200">
                                            {d.appsDescription}
                                        </Text>
                                        <Text size="xl" as="p" className="hidden group-hover:text-[white] group-hover:block text-xs font-light text-deep_purple-A200">
                                            {d.des}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* Revolutionizing Support Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Revolutionizing Support for Educational Institutions with SOOMUCH.AI
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly items-center sm:block  md:flex-wrap md:gap-5">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {revolutionizingSection.map((d, index) => (
                                <div
                                    key={"featuresList12" + index + d.appsDescription}
                                    className="sm:mt-5 sm:m-auto cursor-pointer transition ease-linear delay-200 group hover:bg-deep_purple-A200 relative w-[200px] h-[200px] rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex flex-col items-center justify-center gap-4"
                                >
                                    <div className="group-hover:hidden absolute h-10 w-10 left-2 top-4 rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex justify-center items-center">
                                        <Text size="md" as="p" className="font-bold !text-black-900">
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Img src={d.appsImage} alt="apps image" className="group-hover:hidden h-[70px] w-[49%]" />
                                    <div className="text-center mx-8">
                                        <Text size="xl" as="p" className="group-hover:hidden text-xl font-light text-deep_purple-A200">
                                            {d.appsDescription}
                                        </Text>
                                        <Text size="xl" as="p" className="hidden group-hover:text-[white] group-hover:block text-xs font-light text-deep_purple-A200">
                                            {d.des}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            <div className="flex justify-center">
                <Button
                    size="lg"
                    shape="round"
                    color="deep_purple_400_red_500"
                    className="w-[60%] font-extrabold md:p-5 md:h-[100%] md:w-[90%] "
                    onClick={handleModal}
                >
                    <p>

                        Wanna Apply the Success Formula, Click here for{" "} <span className="underline md:block">{" "}Book a demo</span>
                    </p>
                </Button>
            </div>

            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Revolutionizing Support for Educational Institutions with SOOMUCH.AI
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    {/* Action for Educationl Institutions */}
                    <div className="md:flex justify-evenly hidden items-center sm:block sm:w-[100%] sm:m-auto md:flex-wrap">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {revolutionizingSupport.map((d, index) => (
                                <div key={"featuresList12" + index}
                                    className="cursor-pointer relative md:w-[40%] sm:w-[100%]">
                                    <div className=" p-5 h-[300px] rounded-[15px] border-2 border-white bg-[#8450E7] flex flex-col gap-[1rem] justify-between my-5">
                                        <div className="space-y-4">
                                            <p className="text-[white] font-bold text-xl">{d.des}</p>
                                        </div>
                                        <div className="space-y-4">
                                            <p className="text-[white]">{d.des}</p>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Suspense>
                    </div>
                    <div className="grid grid-cols-12 justify-center items-center rounded-lg bg-gray-100_01 px-4 mb-4 mx-[25px] gap-10 mx-[20px] md:hidden">
                        <div className="col-span-12 text-left">
                            <SlidingCard
                                data={revolutionizingSupport}
                            />
                        </div>
                        {/* <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">Intuitive Dashboard</p>
                            <p className="text-sm text-[white]"> Access comprehensive information at a glance</p>
                        </div>
                        <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">Free Data Migration</p>
                            <p className="text-sm text-[white]">Smoothly transition with our support</p>
                        </div>
                        <div className="col-span-4 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-3xl">SMS Template Approval Support</p>
                            <p className="text-sm text-[white]">Customize communication easily.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* faq section */}
            <FaqSection data={FAQDATA} />

            {/* footer section */}
            <Footer />
        </div>
    );
}

export default AiPoweredManagmentHub;


const FAQDATA = [
    {
        question: "How does the AI-powered ERP ensure efficiency and cost savings compared to traditinal systems?",
        answers: [
            {
                question: "Q. Why should we switch to an AI-powered ERP when our current system works fine?",
                answer: [{
                    bold: "> Current Limitions:",
                    answer: "Traditional systems can be slow and cumbersome, often leading to inefficiencies."
                },
                {
                    bold: "> AI-Powered Advantage:",
                    answer: "The AI-POWERED MANAGMENT HUB offers automated processes, reducing maunal work and errors. This means your staff can focus on more critical tasks, boosting overall efficiency and productivity"
                }
                ]
            },
            {
                question: "Q. How can we be sure that this new system will save us money?",
                answer: [{
                    bold: "> Financial Doubts:",
                    answer: "Switching systems can seem like an added expense."
                },
                {
                    bold: "> Cost Efficiency:",
                    answer: "With the Al-POWERED MANAGEMENT                    HUB, you can save up to 80% on website spending and                    reduce costs associated with manual processes. The system's                    efficiency translates to significant long-term savings."
                }
                ]
            },
        ]
    }, {
        question: "What if data migration is complex and our staff finds it difficult to adapt to the new system?",
        answers: [
            {
                question: "Q. We are concerned about the complexity of migrating our data to a new system ?",
                answer: [{
                    bold: "> Migration Concerns:",
                    answer: "Data migration can seem daunting and complicated."
                },
                {
                    bold: "> Smooth Transition:",
                    answer: "We provide free data migration support, ensuring your data is transferred seamlessly with minimal disruption. Our team of experts will handle the process, making it smooth and stress-free for you."
                }
                ]
            },
            {
                question: "Q. What if our staff finds it difficult to adapt to the new system?",
                answer: [{
                    bold: "> Adaptability Issues:",
                    answer: "New systems can have a learning curve."
                },
                {
                    bold: "> User-Friendly Design:",
                    answer: "Our platform is designed for 5-minute mastery, making it easy for your staff to learn and use. Additionally, our virtual assistants provide ongoing support to ensure a smooth transition."
                }
                ]
            },
        ]
    }, {
        question: "How does the AI-POWERED MANAGEMENT HUB improve        communication and manage multiple branches?",
        answers: [
            {
                question: "Q. How does the Al-POWERED MANAGEMENT HUB improve                communication?",
                answer: [{
                    bold: "> Communication Gaps:",
                    answer: "Effective communication can be                    challenging with traditional systems."
                },
                {
                    bold: "> Improved Communication:",
                    answer: "The HUB includes SMS template approval support, mobile apps, and automated reminders, ensuring timely and effective communication with parents and staff. It supports multilingual notifications, overcoming language barriers and reaching a broader audience."
                }
                ]
            },
            {
                question: "Q. How does this system help in managing multiple branches of our institutions?",
                answer: [{
                    bold: "> Branch Managment:",
                    answer: "Managing multiple branches can we complex."
                },
                {
                    bold: "> Streamlined Mangement:",
                    answer: "Our Super Admin App allows you to get an overview of all institution branches in one place, streamlining managment tasks and ensuring consistency across locations."
                }
                ]
            },
        ]
    }, {
        question: "Why should we trust an AI system for critical tasks and is ongoing support available?",
        answers: [
            {
                question: "Q. Why should we trust an AI system to handle critical administrative tasks?",
                answer: [{
                    bold: "> Trust Issues:",
                    answer: "Relying on AI for critical tasks might seem risky."
                },
                {
                    bold: "> Proven Reliability:",
                    answer: "Our system is inspired by successful models in banking and finance, ensuring robust and reliable performance. The inbuilt virtual assistants provide unparalleled support, ensuring that you have help whenever you need it."
                }
                ]
            },
            {
                question: "Q. Is ongoing support availbale after the initial setup?",
                answer: [{
                    bold: "> Support Concerns:",
                    answer: "Worries about post-setup assistance?"
                },
                {
                    bold: "> Dedicated Assistance:",
                    answer: "yes, we provide ongoing support through our inbuilt virtual assistants. They are available from MONDAY TO SATRUDAY (9.00AM TO 6.00PM) to assist with any issues or queries, ensuring you institution runs smoothly at all times."
                }
                ]
            },
        ]
    }, {
        question: "What customization options are available and how quickly will we see benefits?",
        answers: [
            {
                question: "Q. What customization options are available to suit our specific needs?",
                answer: [{
                    bold: "> Customization Concerns:",
                    answer: "Off-the-shelf systems may not meet specific requirements."
                },
                {
                    bold: "> Personalized Solutions:",
                    answer: "We offer one free customization for fee and report cards to cater to your institution's unique needs. This ensures that the system fits seamlessly into your existing processes."
                }
                ]
            },
            {
                question: "Q. How quickly can we expect to see benefits after implementing the system?",
                answer: [{
                    bold: "> Immediate Benefits:",
                    answer: "Concerned about the time it takes to see improvements?"
                },
                {
                    bold: "> Swift Results:",
                    answer: "The efficiency and automation provided by the AI-POWERED MANAGMENT HUB lead to immediate improvements in fee collection, communication, and administrative processes. You will notice postive changes within weeks of implementaion."
                }
                ]
            },
        ]
    }
]
