import { Helmet } from "react-helmet";
import { Heading, Img, Button, Text } from "../../components";
import Faq from "../../components/Faq";
import CommunicationSection from "../../components/CommunicationSection";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom';
import HeroTitle from "components/HeroTitle";
import SlidingCard from "components/SlidingCard";
import FaqSection from "components/FaqSection";
import { Link as AnchorTag } from 'react-scroll';
import InboundButton from "components/InboundButton";

const data = [
    { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
    { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
    { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
    { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
    { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

const data1 = [
    { appsImage: "images/3_mobile_app.svg", appsDescription: "3 Mobile Apps" },
    { appsImage: "images/5_min_mastery.svg", appsDescription: "5-Minute Mastery" },
    { appsImage: "images/1_free_customization.svg", appsDescription: "1 Free Customization" },
    { appsImage: "images/inbuild_virtual_assistance.svg", appsDescription: "INBUILT Virtual Assistants" },
];

const mobileSection = [
    { appsImage: "images/mobile_super_admin.svg", appsDescription: "Super Admin App" },
    { appsImage: "images/parent_app.svg", appsDescription: "Parent App" },
    { appsImage: "images/faculty_app.svg", appsDescription: "Faculty App" },
];

const unlockSoomuchAISection = [
    { appsImage: "images/rapid_learning.svg", appsDescription: "Rapid Learning" },
    { appsImage: "images/effortless_operations.svg", appsDescription: "Effortless Operation" },
    { appsImage: "images/intelligent_search.svg", appsDescription: "Intelligent Search" },
];

const tailoredSection = [
    { appsImage: "images/flexiblity.svg", appsDescription: "Flexibility" },
    { appsImage: "images/complementry_customization.svg", appsDescription: "Complimentary Customization" },
    { appsImage: "images/seamless_fit.svg", appsDescription: "Seamless Fit" },
];

const revolutionizingSection = [
    { appsImage: "images/fee_collection_sol_1.svg", appsDescription: "Automated Reminders", des: "AUTO FEE HUB sends automated reminders to parents about pending fees, reducing the risk of overdue payments." },
    { appsImage: "images/fee_collection_sol_2.svg", appsDescription: "Seamless Setup", des: "Our virtual assistants will assist you in setting up the system, ensuring a hassle-free experience for your institution." },
    { appsImage: "images/fee_collection_sol_3.svg", appsDescription: "First in India", des: "We take pride in being the first in india to implement an automated Fee Follow - up and collection process" },
    { appsImage: "images/fee_collection_sol_4.svg", appsDescription: "Efficient Process", des: "Once set up, the fee collection process becomes entirely automated saving time and resources." },
    { appsImage: "images/fee_collection_sol_5.svg", appsDescription: "Efficient Communication", des: "Automated fee follow - up ensures timely and effective communication about fee dues and arrears." },
];

const automateFeeFollowUp = [
    { title: "Cost-Effective", des: "Reduces the time and cost associated with traditional communication methods." },
    { title: "Multilingual Communication", des: "Overcomes the language barrier by providing notifications in multiple languages." },
    { title: "Higher Reach", des: "Ensures a higher reach as compared to traditional methods, increasing the likelihood of parents receiving important information" },
    { title: "Positive Customer Relations", des: "Avoids conflicts and bitterness, fostering positive relations between parents and the institution" }
]

const FeeCollectionProcess = ({ handleModal }) => {

    let demoData: any = '';

    try {
        console.log("checking");
        demoData = JSON.parse(localStorage.getItem('demo_data')) || "";
    } catch (err) {
        console.log("err ", err);
    }

    return (
        <div className="flex flex-col gap-8">
            {/* navigation bar section */}

            {/* hero section */}
            <div className="flex flex-col gap-[50px]">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-4 md:items-end">
                        <Button
                            size="xs"
                            variant="fill"
                            className="min-w-[250px] hidden md:block rounded-bl-[10px] rounded-tl-[10px] font-extrabold sm:px-5"
                        >
                            Patent Filed
                        </Button>
                    </div>

                    <div className="flex flex-row relative">
                        <div className="container-xs md:p-5 md:px-5">
                            <div className="flex flex-col items-center gap-1 sm:text-center">
                                <Heading size="8xl" as="h1" className="!font-poppins !text-black-900_b2">
                                    India’s First Business Ecosystem for{" "}
                                </Heading>
                                <HeroTitle />
                            </div>
                        </div>
                        <Button
                            size="xs"
                            variant="fill"
                            className="absolute top-2 right-0 min-w-[250px] rounded-bl-[10px] rounded-tl-[10px] font-extrabold md:hidden"
                        >
                            Patent Filed
                        </Button>
                    </div>
                    <div className="relative self-stretch">
                        <div className="flex w-full flex-col">
                            <div className="relative z-[1] flex flex-col items-center">
                                <div className="grid grid-cols-5 px-2 h-[9.3vh] md:h-[7.3vh] gap-12 sm:h-auto sm:gap-2 sm:px-4">
                                    <Link to={"/"}>
                                        <img src="/images/img_a1.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/digital-marketing"}>
                                        <img src="/images/img_a2_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/customer-satisfaction"}>
                                        <img src="/images/img_a3_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/fee-collection-process"}>
                                        <img src="/images/img_a4_active.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                    <Link to={"/ai-powered-managment-hub"}>
                                        <img src="/images/img_a5.svg" className="h-[75px] sm:h-[40px]" alt="" />
                                    </Link>
                                </div>
                                <div className="container-xs relative z-[-1] pl-[360px] sm:pl-[60px] sm:pr-[140px] sm:m-0 md:pl-[10px]">
                                    <Img src="images/img_arrow_4.svg" alt="arrow image" className="h-[130px] sm:h-[120px] md:h-[150px]" />
                                </div>
                            </div>
                            <div className="container-xs relative pl-[35px] pr-9 md:p-5 sm:px-5 sm:py-0">
                                <div className="flex items-center justify-between items-stretch gap-5 md:flex-col">
                                    <div className=" py-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[39%] flex-col items-center gap-3 rounded-tl-[52px] rounded-br-[52px] border border-solid bg-gradient5  md:w-full sm:p-5">
                                        <Heading
                                            size="s"
                                            as="h3"
                                            className="w-[95%] text-center !font-semibold leading-[22px] !text-black-900 md:w-full"
                                        >
                                            Automating Fee Collection not only ensures timely payments and reduces overdue fees, but it also saves at least 1 Lakh per year by eliminating the need for a separate team to manage it.
                                        </Heading>
                                        <div className="flex w-[92%] justify-between gap-5 md:w-full sm:flex-col sm:items-center">
                                            <Button
                                                // disabled={demoData?.SOURCE === "FEE" || demoData?.SOURCE_ARRAY?.includes("FEE")}
                                                color="white_A700_01"
                                                size="xl"
                                                variant="fill"
                                                shape="round"
                                                className="min-w-[160px] font-medium"
                                                onClick={handleModal}
                                            >
                                                {!!demoData?.DEMO_CAMPAIGN_ID ? "Live Demo" : "Book a LIVE Demo"}
                                            </Button>
                                            <AnchorTag
                                                activeClass="active"
                                                to={"AUTO_CONNECT"}
                                                spy={true}
                                                smooth={true}
                                                offset={-120}
                                                duration={1000}
                                            >
                                                <Button
                                                    size="xl"
                                                    variant="fill"
                                                    shape="round"
                                                    className="min-w-[160px] font-medium shadow-sm sm:px-5"
                                                >
                                                    Know More
                                                </Button>
                                            </AnchorTag>
                                        </div>
                                    </div>
                                    <div className="pb-2 white_A700_01_white_A700_7f_border drop-shadow-sm flex w-[35%] justify-center rounded-tl-[52px] rounded-br-[52px] bg-gradient5 md:w-full">
                                        <div className="flex w-full flex-col items-center gap-[9px] px-6">
                                            <Heading
                                                size="s"
                                                as="h4"
                                                className="flex items-center justify-center rounded-bl-[10px] rounded-br-[10px] bg-purple-900 px-[22px] py-[3px] !font-reemkufi sm:px-5"
                                            >
                                                POTENTIAL EARNINGS
                                            </Heading>
                                            <div className="mt-2 flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Earning minimum:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>1,00,000
                                                </Text>
                                            </div>
                                            <div className="mt-2 flex flex-wrap items-center justify-between gap-5 self-stretch">
                                                <Heading size="lg" as="h6" className="!text-black-900">
                                                    Total Earnings:
                                                </Heading>
                                                <Text size="md" as="p" className="!text-black-900">
                                                    <span className="font-inter"> ₹</span>5,00,000
                                                </Text>
                                            </div>
                                            <div className="mt-2">
                                                <Heading size="md" as="h6" className="!font-semibold !text-red-A700_99">
                                                    *Above data is subject to Terms and Conditions.
                                                </Heading>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-0 right-0 top-[37%] z-[2] m-auto flex w-[35%] items-center justify-center gap-2 rounded-md border border-solid border-black-900 bg-gray-100_02 sm:relative sm:hidden md:top-[15%] md:w-[60%]">
                            <Button
                                size="md"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[29%] rounded-bl-md rounded-br-2xl rounded-tl-md rounded-tr-2xl !bg-gradient font-poppins font-bold"
                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="w-[71%] text-center leading-[23px] !text-black-900 sm:w-full sm:p-5">
                                <>
                                    It starts strong word-of-mouth advertising <br />
                                    and earns at least 1 lakh per year
                                </>
                            </Text>
                        </div>
                        <div className=" hidden sm:flex absolute left-[10%] right-0 top-[8%] z-[2] m-auto flex w-[32%] items-center justify-center rounded-md border border-solid border-black-900 bg-gray-100_02 sm:w-[80%]">
                            <Button

                                size="xl"
                                color="deep_purple_400_red_500"
                                className="cursor-default min-w-[20%] sm:p-0 rounded-bl-md rounded-br-xl rounded-tl-md rounded-tr-xl !bg-gradient font-poppins font-bold sm:h-[40px] sm:text-xs"                            >
                                NEXT
                            </Button>
                            <Text size="s" as="p" className="text-center leading-[18px] !text-black-900 sm:w-full px-1 sm:text-xs">
                                <>
                                    It starts strong word-of-mouth advertising
                                    and earns at least 1 lakh per year
                                </>
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xs my-2">
                <Img
                    src="images/img_mask_group.png"
                    alt="masked image"
                    className="h-[10px] w-full rounded-md object-cover"
                />
            </div>

            {/* about section */}
            <AboutSection />

            {/* features section */}
            <div id={"AUTO_CONNECT"} className="mt-[50px] flex flex-col bg-gray-100_01 pb-[50px] pt-[45px] md:py-5">
                <div className="container-xs flex flex-col items-center gap-[47px] pl-[37px] pr-[34px] md:p-5 sm:px-5">
                    <div className="flex flex-col items-start gap-10 self-stretch">
                        <div className="flex w-[95%] items-center gap-[41px] md:w-full md:flex-col sm:flex-col-reverse">
                            <Img
                                src="images/img_group_151x150_4.svg"
                                alt="logo image"
                                className="h-[151px] w-[150px] object-cover md:w-full md:object-contain	sm:m-auto"
                            />
                            <Heading
                                size="8xl"
                                as="h2"
                                className="w-[86%] bg-gradient2 bg-clip-text text-center !font-lexenddeca leading-[50px] !text-transparent md:w-full sm:leading-[40px]"
                            >
                                <span className="bg-gradient2 bg-clip-text text-transparent">
                                    AUTO FEE HUB: Simplifying Fee <br /> Collection Process
                                </span>
                            </Heading>
                        </div>
                        <Heading
                            size="9xl"
                            as="h3"
                            className="ml-3 bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent md:ml-0"
                        >
                            <span className="bg-gradient2 bg-clip-text text-transparent">Why Choose&nbsp;</span>
                            <span className="bg-gradient2 bg-clip-text text-[43px] text-transparent">AUTO FEE HUB ?</span>
                        </Heading>
                        <Heading
                            size="8xl"
                            as="h4"
                            className="ml-[27px] w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-gray-900_01 md:ml-0"
                        >
                            <>
                                AUTO FEE HUB streamlines the fee collection process, ensuring timely payments from parents and minimizing overdue payments. Integrated with online payment gateways, SMS, calls, mobile apps, and WhatsApp, it guarantees efficient fee collection. Inspired by successful models in the banking, insurance, mobile, and microfinance sectors, our system ensures efficient communication and fee collection
                            </>
                        </Heading>
                    </div>
                    <div className="flex w-[92%] flex-col items-center justify-center gap-11 rounded-[40px] bg-deep_purple-100 px-14 pb-[166px] pt-[139px] md:w-full md:p-5">
                        <Button size="5xl" shape="square" className="w-[100px]">
                            <Img src="images/img_ellipse_557.svg" />
                        </Button>
                        <Heading as="h5" className="!text-[21.94px] !text-deep_purple-700">
                            VIDEO COMING SOON
                        </Heading>
                    </div>
                    <InboundButton />
                    {/* <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Ready to Transform Your Communication?
                        </Heading>
                        <Button
                            size="lg"
                            shape="round"
                            color="deep_purple_400_red_500"
                            className="min-w-[392px] font-extrabold sm:px-5"
                            onClick={handleModal}
                        >
                            Click here for a LIVE demo
                        </Button>
                    </div> */}
                </div>
            </div>

            {/* risks without product section */}
            <div className="container-xs relative rounded-[40px] bg-gray-100_01 py-8 px-4 sm:p-3 sm:mx-[5%] sm:w-[90%]">
                <div className="m-auto flex h-max w-[94%] flex-col items-start">
                    <div className="flex w-[95%] items-center gap-8 md:w-full md:flex-col">
                        <Img src="images/img_group_black_900.svg" alt="group image" className="h-[60px] w-[7%] md:w-full" />
                        <Heading size="8xl" as="h2" className="self-end !font-lexenddeca !text-deep_orange-A400">
                            What You Stand to Lose without AUTO FEE HUB
                        </Heading>
                    </div>
                    <Heading size="8xl" as="h3" className="my-8 !font-lexenddeca !text-red-900 sm:mt-[30px]">
                        If you don't opt for AUTO FEE HUB, you risk:
                    </Heading>
                    <Heading
                        size="8xl"
                        as="h4"
                        className="w-full !font-semibold leading-[150%] tracking-[-0.77px] !text-black-900"
                    >
                        <>
                            &gt;  Revenue Loss: Without timely fee collection, your institution may suffer from revenue loss due to overdue payments.
                            <br />
                            &gt; Financial Strain: Unpaid fees can create financial strain, affecting the institution's budget and operations.
                            <br />
                            &gt; Time Wastage: Manual fee collection processes can be time-consuming and inefficient, diverting staff resources from other essential tasks
                        </>
                    </Heading>
                </div>
            </div>


            {/* Revolutionizing Support Section */}
            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            The Solution
                        </Heading>
                        <div className="w-full h-2 bg-[white] rounded-lg"></div>
                    </div>
                    <div className="flex justify-evenly items-center sm:block md:flex-wrap md:gap-5">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {revolutionizingSection.map((d, index) => (
                                <div
                                    key={"featuresList12" + index}
                                    className="cursor-pointer sm:m-auto transition ease-linear delay-200 group hover:bg-deep_purple-A200 relative w-[200px] h-[200px] rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex flex-col items-center justify-center gap-4 sm:mt-5"
                                >
                                    <div className="group-hover:hidden absolute h-10 w-10 left-2 top-4 rounded-full border-deep_purple-A200 border-2 bg-white-A700 flex justify-center items-center">
                                        <Text size="md" as="p" className="font-bold !text-black-900">
                                            {index + 1}
                                        </Text>
                                    </div>
                                    <Img src={d.appsImage} alt="apps image" className="group-hover:hidden h-[70px] w-[49%]" />
                                    <div className="text-center mx-8">
                                        <Text size="xl" as="p" className="group-hover:hidden text-xl font-light text-deep_purple-A200">
                                            {d.appsDescription}
                                        </Text>
                                        <Text size="xl" as="p" className="hidden group-hover:text-[white] group-hover:block text-xs font-light text-deep_purple-A200">
                                            {d.des}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </Suspense>
                    </div>
                </div>
            </div>

            {/* live demo cta section */}
            <div className="flex justify-center md:text-center md:w-[80%] md:m-auto">
                <div className="flex w-[82%] flex-col items-center gap-[5px] rounded-[20px] bg-white-A700_01 px-[30px] pb-[23px] pt-[30px] shadow-xl md:w-full sm:p-5">
                    <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                        Ready to Transform Your Communication?
                    </Heading>
                    <Button
                        size="lg"
                        shape="round"
                        color="deep_purple_400_red_500"
                        className="min-w-[392px] font-extrabold sm:px-5 sm:min-w-[292px] sm:h-[76px]"
                        onClick={handleModal}
                    >
                        Click here for a LIVE demo
                    </Button>
                </div>
            </div>

            <div className="flex flex-col justify-center rounded-[40px] bg-gray-100_01 py-8 mx-[25px]">
                <div className="text-center container-xs flex flex-col gap-10 pl-[38px] pr-[34px]">
                    <div className="w-full flex flex-col items-center gap-4">
                        <Heading size="8xl" as="h6" className="bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent">
                            Advantages of Automated Fee Follow-up
                        </Heading>
                    </div>
                    {/* Action for Educationl Institutions */}
                    <div className="md:flex justify-evenly hidden items-center sm:block sm:w-[100%] sm:m-auto md:flex-wrap">
                        <Suspense fallback={<div>Loading feed...</div>}>
                            {automateFeeFollowUp.map((d, index) => (
                                <div key={"featuresList12" + index}
                                    className="cursor-pointer relative md:w-[40%] sm:w-[100%]">
                                    <div className=" p-5 h-[300px] rounded-[15px] border-2 border-white bg-[#8450E7] flex flex-col gap-[1rem] justify-between my-5">
                                        <div className="space-y-4">
                                            <p className="text-[white] font-bold text-xl">{d.title}</p>
                                        </div>
                                        <div className="space-y-4">
                                            <p className="text-[white]">{d.des}</p>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Suspense>
                    </div>
                    <div className="grid grid-cols-12 justify-center items-center rounded-lg bg-gray-100_01 px-4 mb-4 gap-10 mx-[20px] md:hidden">
                        <div className="col-span-12 text-left">
                            <SlidingCard
                                data={automateFeeFollowUp}
                            />
                        </div>
                        {/* <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-xl">Cost-Effective</p>
                            <p className="text-sm text-[white]">Reduces the time and cost associated with traditional communication methods.</p>
                        </div>
                        <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-xl">Multilingual Communication</p>
                            <p className="text-sm text-[white]">Overcomes the language barrier by providing notifications in multiple languages.</p>
                        </div>
                        <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-xl">Higher Reach</p>
                            <p className="text-sm text-[white]">Ensures a higher reach as compared to traditional methods, increasing the likelihood of parents receiving important information</p>
                        </div>
                        <div className="col-span-3 bg-[#8450E7] rounded-xl border-4 border-[white] flex flex-col justify-center items-center px-4 h-[250px] text-center gap-5">
                            <p className="text-[white] font-bold text-xl">Positive Customer Relations</p>
                            <p className="text-sm text-[white]">Avoids conflicts and bitterness, fostering positive relations between parents and the institution</p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="mt-6 flex justify-center">
                <Button
                    size="lg"
                    shape="round"
                    color="deep_purple_400_red_500"
                    className="w-[60%] font-extrabold sm:px-5 md:p-5 md:w-[80%] md:h-auto"
                    onClick={handleModal}
                >
                    <p>
                        Wanna Apply the Success Formula, Click here for{" "} <span className="underline">{" "}Book a demo</span>
                    </p>
                </Button>
            </div>

            {/* faq section */}
            <FaqSection height="h-[480px]" data={FAQDATA} />

            {/* footer section */}
            <Footer />
        </div>
    );
}

export default FeeCollectionProcess;

const FAQDATA = [
    {
        question: "Why should we switch to AUTO FEE HUB when our manual process works fine?",
        answers: [
            {
                question: "Q. Why should we switch to AUTO FEE HUB when our manual process works fine?",
                answer: [{
                    bold: "> Revenue Loss:",
                    answer: "Manual processes can lead to missed                    payments and revenue loss."
                },
                {
                    bold: "> Financial Strain:",
                    answer: "Unpaid fees can create budget constraints."
                },
                {
                    bold: "> Time Wastage:",
                    answer: "Manual collection is time-consuming and                    inefficient"
                }
                ]
            },
            {
                question: "",
                answer: [{
                    bold: "Solution: AUTO FEE HUB",
                    answer: "automates reminders and                    collections, ensuring timely payments and freeing up staff for                    other important tasks."
                }
                ]
            },
        ]
    }, {
        question: "Is it really worth the cost to implement AUTO FEE HUB?",
        answers: [
            {
                question: "Q. We are concerned about the complexity of migrating our data to a new system ?",
                answer: [{
                    bold: "> Cost-Effective:",
                    answer: "Reduces costs associated with traditional                    methods, like paper, phone calls, and administrative                    overhead."
                },
                {
                    bold: "> ROl:",
                    answer: "Increased timely payments, as it reduces arrears and                    improves cash flow. Best part is its free."
                }
                ]
            },
            {
                question: "Q. We already have a system in place. How difficult is it to                transition to AUTO FEE HUB?",
                answer: [{
                    bold: "> Seamless Setup:",
                    answer: "Our virtual assistants help with the                    setup, ensuring a smooth transition with minimal disruption."
                },
                {
                    bold: "> Integration:",
                    answer: "AUTO FEE HUB integrates with existing                    systems, making the transition easy and efficient."
                }
                ]
            },
            {
                question: "Training: We provide training sessions for your staff to                ensure they are comfortable and proficient with the new                system.",
            },
        ]
    }, {
        question: "How does the system support less tech-savvy parents while maintaining positive relationships with all?",
        answers: [
            {
                question: "Q. We have parents who are not tech-savvy. How will they manage                this system?",
                answer: [{
                    bold: "> Multilingual Communication:",
                    answer: "Notifications are sent in multiple                    languages to cater to diverse demographics."
                },
                {
                    bold: "> Higher Reach:",
                    answer: "The system uses various communication channels                    (SMS, calls, WhatsApp, apps), ensuring even less tech-savvy parents                    receive important information."
                },
                {
                    bold: "> Support:",
                    answer: "Our virtual assistants help set up and explain the system to                    parents, ensuring everyone is comfortable using it."
                }
                ]
            },
            {
                question: "Q. Will this system affect our relationship with parents negatively?",
                answer: [{
                    bold: "> Positive Relations:",
                    answer: "Automated reminders prevent awkward fee                    discussions, fostering a more positive relationship."
                },
                {
                    bold: "> Transparency:",
                    answer: "Keeps parents informed about dues and payments,                    reducing misunderstandings."
                },
                {
                    bold: "> Personal Touch:",
                    answer: "Automated systems handle routine tasks, allowing                    your staff to engage with parents on more meaningful issues."
                }
                ]
            },
        ]
    }, {
        question: "How can automated fee reminders be better than personal calls from our staff?",
        answers: [
            {
                question: "Q. How can automated fee reminders be better than                personal calls from our staff?",
                answer: [{
                    bold: "> Automated Reminders:",
                    answer: "AUTO FEE HUB sends timely,                    consistent reminders via SMS, calls, WhatsApp, and mobile                    apps, reducing human error and ensuring parents are always                    informed."
                },
                {
                    bold: "> Efficiency:",
                    answer: "Automated systems handle repetitive tasks                    quickly and accurately, allowing your staff to focus on more                    complex issues and personal interactions when necessary"
                }
                ]
            }
        ]
    }, {
        question: "How does AUTO FEE HUB ensure system reliability and data security?",
        answers: [
            {
                question: "Q. How does AUTO FEE HUB ensure data security and                privacy?",
                answer: [{
                    bold: "> Data Security:",
                    answer: "Uses advanced encryption methods to                    protect sensitive information."
                },
                {
                    bold: "> Compliance:",
                    answer: "Adheres to all relevant data protection                    regulations, ensuring privacy and security.                    Trust: Your data is safe with us, allowing you to focus on                    your core mission of education."
                }
                ]
            },
            {
                question: "Q. What if the system fails or there's a technical issue?",
                answer: [{
                    bold: "> Reliable System:",
                    answer: "AUTO FEE HUB is designed to be robust                    and reliable, with minimal downtime."
                },
                {
                    bold: "> Support:",
                    answer: "Our technical support team is always ready to                    assist with any issues, ensuring smooth operation.                    Backup Plans: In case of technical failures, manual                    processes can temporarily take over until the issue is                    resolved."
                }
                ]
            },
            {
                question: "Backup Plans: In case of technical failures, manual                processes can temporarily take over until the issue is                resolved."
            },
        ]
    }
]
