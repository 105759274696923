import { Button, Heading, Img } from "..";
import React from "react";
import { Link as AnchorTag } from 'react-scroll';

interface Props {
  className?: string;
  columntwotext?: string;
  boosttext?: string;
  socialhubbutton?: string;
  image?: string;
}

const data = [
  { columntwotext: "1", boosttext: "Effective Multilingual Communication with", socialhubbutton: "AUTO CONNECT", image: "images/journy_count1.svg" },
  { columntwotext: "2", boosttext: "Boost Your Online Presence with", socialhubbutton: "SOCIAL HUB", image: "images/journy_count2.svg" },
  { columntwotext: "3", boosttext: "Streamline Fee Reminders with ", socialhubbutton: "AUTO FEE HUB", image: "images/journy_count3.svg" },
  { columntwotext: "4", boosttext: "Gather Valuable Insights with ", socialhubbutton: "PARENTAL INSIGHTS", image: "images/journy_count4.svg" },
  { columntwotext: "5", boosttext: "Optimize Operations with", socialhubbutton: " AI-POWERED MANAGEMENT HUB", image: "images/journy_count5.svg" },
];

export default function AboutSection({
  columntwotext = "2",
  boosttext = "Boost Your Online Presence with",
  socialhubbutton = "SOCIAL HUB",
  ...props
}: Props) {
  return (
    <div className="container-xs">
      <div className="flex flex-col items-center gap-[27px] md:w-full px-8">
        <Heading
          size="10xl"
          as="h2"
          className="self-start bg-gradient2 bg-clip-text !font-lexenddeca !text-transparent"
        >
          SOOMUCH.AI&#39;s Journey to Worldwide Success{" "}
        </Heading>
        <Heading size="5xl" as="h3" className="w-full leading-[150%] tracking-[-0.55px] !text-gray-900_01">
          We delve into the secrets of successful global businesses and adapt these strategies specifically for
          educational institutions. As India&#39;s first business ecosystem for schools, SOOMUCH.AI offers a
          step-by-step process with five key elements of success. Our approach can increase revenue by up to 30%
          and reduce operational costs by up to 80%.
        </Heading>
        {/* <div className="relative h-[55px] w-[8%] md:h-auto md:w-full">
    <Img src="images/img_contrast.svg" alt="contrast image" className="h-[42px] w-[97%]" />
    <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[55px] w-full md:h-auto">
      <div className="mt-[13px] flex h-[20px] w-[37%] flex-col items-center bg-[url(/public/images/img_group_26.svg)] bg-cover bg-no-repeat px-px pb-0.5 pt-px md:h-auto">
        <Heading size="xs" as="h4" className="tracking-[-0.29px] !text-black-900">
          1
        </Heading>
      </div>
    </div>
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-[55px] w-max flex-col items-center bg-[url(/public/images/img_group_25.svg)] bg-cover bg-no-repeat pb-[53px] pl-[11px] pr-[42px] md:h-auto md:pb-5 md:pr-5">
        <Img src="images/img_vector.svg" alt="vector image" className="h-px w-px" />
      </div>
  </div> */}
        <div className="flex flex-col w-[70%] gap-6 sm-w[100%]">
          {/* <Suspense fallback={<div>Loading feed...</div>}> */}
          {data.map((data, index) => (
            <div key={"sadbkwqudesajdbjkqwsad" + index} className="grid grid-cols-12 gap-4 sm:flex sm:flex-col sm:justify-center sm:text-center">
              <div className="col-span-1 sm:col-span-3 sm:m-auto sm:flex sm:justify-center">
                <img src={data?.image || "images/journy_count1.svg"} alt="1" className="h-[55px]" />
              </div>
              <div className="col-span-11 flex items-center gap-4 sm:flex-col">
                <Heading
                  size="4xl"
                  as="h5"
                  className="ml-7 mt-2 self-start tracking-[-0.59px] !text-gray-900_01 md:ml-0"
                >
                  {data?.boosttext}
                </Heading>
                <AnchorTag
                  activeClass="active"
                  to={data?.socialhubbutton === "AUTO CONNECT" ? "AUTO_CONNECT" : "#"}
                  spy={true}
                  smooth={true}
                  offset={-120}
                  duration={1000}
                >
                  <Button
                    shape="round"
                    color="deep_purple_400_red_500"
                    className="ml-5 min-w-[181px] font-extrabold md:ml-0 sm:h-auto sm:p-2"
                  >
                    {data?.socialhubbutton}
                  </Button>
                </AnchorTag>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
