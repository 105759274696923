// import Marquee from "components/Marquee";
import { Text, Img, Heading } from "..";
import React, { Suspense } from "react";
import Marquee from "react-fast-marquee";

interface Props {
  className?: string;
  groupten?: string;
  welcometext?: string;
  welcomecallontext?: React.ReactNode | string;
  groupnine?: string;
  appdownloadtext?: React.ReactNode | string;
  groupeight?: string;
  attendancetext?: string;
  groupseven?: string;
  homeworktext?: React.ReactNode | string;
  groupsix?: string;
  examdataandresultstext?: string;
}

const outboundComprehensiveSection = [
  { appsImage: "images/img_menu.svg", appsDescription: "Welcome Call on New Admissions" },
  { appsImage: "images/img_thumbs_up.svg", appsDescription: "App Download Calls" },
  { appsImage: "images/img_group.svg", appsDescription: "Attendance Notifications" },
  { appsImage: "images/img_thumbs_up_gray_800.svg", appsDescription: "Homework Updates" },
  { appsImage: "images/img_group_1707480072.svg", appsDescription: "Exam Data and Results" },
]

export default function CommunicationSection({
  groupten = "1",
  welcometext = "WELCOME",
  welcomecallontext = (
    <>
      Welcome Call on <br />
      New Admissions
    </>
  ),
  groupnine = "2",
  appdownloadtext = (
    <>
      App Download
      <br /> Calls
    </>
  ),
  groupeight = "3",
  attendancetext = "Attendance Notifications",
  groupseven = "4",
  homeworktext = (
    <>
      Homework
      <br />
      Updates
    </>
  ),
  groupsix = "5",
  examdataandresultstext = "Exam Data and Results",
  ...props
}: Props) {
  return (
    <div className="flex justify-evenly items-center">
      <Suspense fallback={<div>Loading feed...</div>}>
        <Marquee speed={50}>
          {outboundComprehensiveSection.map((d, index) => (
            <div
              key={"featuresList12" + index}
              className="mx-10 relative w-[200px] h-[200px] rounded-full bg-deep_purple-A200 flex flex-col items-center justify-center gap-4 sm:mx-3"
            >
              <div className="absolute h-10 w-10 left-2 top-4 rounded-full bg-white-A700 border-deep_purple-A200 border-2 flex justify-center items-center">
                <Text size="md" as="p" className="font-bold !text-black-900">
                  {index + 1}
                </Text>
              </div>
              <Img src={d.appsImage} alt="apps image" className="h-[70px] w-[49%]" />
              <div className="text-center mx-8">
                <Text size="lg" as="p" className="text-lg font-light !text-white-A700">
                  {d.appsDescription}
                </Text>
              </div>
            </div>
          ))}
        </Marquee>
      </Suspense>
    </div>
    // <div
    //   {...props}
    //   className={`${props.className} flex md:flex-col pt-[50px] pb-[51px] pl-[30px] pr-[25px] gap-[71px] md:py-5 sm:p-5`}
    // >
    //   <div className="flex w-full flex-col rounded-[90px] bg-deep_purple-A200_04 px-[5px] pb-12 pt-[5px] md:pb-5">
    //     <div className="flex flex-col gap-[3px]">
    //       <div className="flex w-[69%] items-start gap-[15px] md:w-full">
    //         <Heading
    //           size="xs"
    //           as="p"
    //           className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] border border-solid border-deep_purple-A200 bg-white-A700_01 text-center tracking-[-0.29px] !text-black-900"
    //         >
    //           {groupten}
    //         </Heading>
    //         <div className="relative mt-[30px] h-[55px] flex-1">
    //           <Img
    //             src="images/img_menu.svg"
    //             alt="menu image"
    //             className="absolute bottom-[0.11px] right-[0.28px] m-auto h-[39px]"
    //           />
    //           <div className="absolute left-[0.00px] top-[0.00px] m-auto flex h-[28px] items-center bg-[url(/public/images/img_group_1707480067.svg)] bg-cover bg-no-repeat px-1 pb-[13px] pt-2 md:h-auto">
    //             <Text size="xs" as="p" className="!font-arialroundedmtbold !text-[5.37px] !text-deep_purple-A200_05">
    //               {welcometext}
    //             </Text>
    //           </div>
    //         </div>
    //       </div>
    //       <Text size="lg" as="p" className="ml-1.5 text-center !font-inter leading-[19px] !text-white-A700_01 md:ml-0">
    //         {welcomecallontext}
    //       </Text>
    //     </div>
    //   </div>
    //   <div className="flex w-full flex-col rounded-[90px] bg-deep_purple-A200_04 px-[5px] pb-12 pt-[5px] md:pb-5">
    //     <div className="flex flex-col gap-[3px]">
    //       <div className="flex w-[68%] items-start gap-[13px] md:w-full">
    //         <Heading
    //           size="xs"
    //           as="p"
    //           className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] border border-solid border-deep_purple-A200 bg-white-A700_01 text-center tracking-[-0.29px] !text-black-900"
    //         >
    //           {groupnine}
    //         </Heading>
    //         <Img src="images/img_thumbs_up.svg" alt="thumbs up" className="mt-[30px] h-[55px] w-[55px]" />
    //       </div>
    //       <Text size="lg" as="p" className="ml-1.5 text-center !font-inter leading-[19px] !text-white-A700_01 md:ml-0">
    //         {appdownloadtext}
    //       </Text>
    //     </div>
    //   </div>
    //   <div className="flex w-full flex-col rounded-[90px] bg-deep_purple-A200_04 px-[5px] pb-12 pt-[5px] md:pb-5">
    //     <div className="flex flex-col gap-[3px]">
    //       <div className="flex w-[64%] items-start gap-[19px] md:w-full">
    //         <Heading
    //           size="xs"
    //           as="p"
    //           className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] border border-solid border-deep_purple-A200 bg-white-A700_01 text-center tracking-[-0.29px] !text-black-900"
    //         >
    //           {groupeight}
    //         </Heading>
    //         <Img src="images/img_group.svg" alt="image" className="mt-[30px] h-[55px] w-[50%]" />
    //       </div>
    //       <Text size="lg" as="p" className="text-center !font-inter leading-[19px] !text-white-A700_01">
    //         {attendancetext}
    //       </Text>
    //     </div>
    //   </div>
    //   <div className="flex w-full flex-col items-center rounded-[90px] bg-deep_purple-A200_04 px-[5px] pb-12 pt-[5px] md:pb-5">
    //     <div className="flex w-[91%] flex-col gap-[3px] md:w-full">
    //       <div className="flex w-[69%] items-start gap-[11px] md:w-full">
    //         <Heading
    //           size="xs"
    //           as="p"
    //           className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] border border-solid border-deep_purple-A200 bg-white-A700_01 text-center tracking-[-0.29px] !text-black-900"
    //         >
    //           {groupseven}
    //         </Heading>
    //         <Img src="images/img_thumbs_up_gray_800.svg" alt="thumbsup" className="mt-[30px] h-[55px] w-[55px]" />
    //       </div>
    //       <Text size="lg" as="p" className="text-center !font-inter leading-[19px] !text-white-A700_01">
    //         {homeworktext}
    //       </Text>
    //     </div>
    //   </div>
    //   <div className="flex w-full flex-col items-center rounded-[90px] bg-deep_purple-A200_04 px-[5px] pb-12 pt-[5px] md:pb-5">
    //     <div className="flex w-[90%] flex-col gap-[3px] md:w-full">
    //       <div className="flex w-[68%] items-start gap-2 md:w-full">
    //         <Heading
    //           size="xs"
    //           as="p"
    //           className="flex h-[40px] w-[40px] items-center justify-center rounded-[20px] border border-solid border-deep_purple-A200 bg-white-A700_01 text-center tracking-[-0.29px] !text-black-900"
    //         >
    //           {groupsix}
    //         </Heading>
    //         <Img src="images/img_group_1707480072.svg" alt="image" className="mt-[30px] h-[55px] w-[55px]" />
    //       </div>
    //       <Text size="lg" as="p" className="text-center !font-inter leading-[19px] !text-white-A700_01">
    //         {examdataandresultstext}
    //       </Text>
    //     </div>
    //   </div>
    // </div>
  );
}
