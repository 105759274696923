import { Heading } from "components/Heading";
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HeroTitleText = [
    {
        text: "Educational Institutions"
    }, {
        text: "Schools"
    }, {
        text: "Colleges"
    }, {
        text: "Universities"
    }, {
        text: "Coaching Centers"
    }
]

const HeroTitle = () => {
    return (
        <Carousel className="h-[50px]" autoPlay showArrows={false} infiniteLoop axis="vertical" showIndicators={false} showStatus={false} showThumbs={false}>
        {HeroTitleText?.map((item, index) => <Heading
            key={"andsjasdnmasdasd" + index}
            size="9xl"
            as="h2"
            className="w-full bg-gradient2 bg-clip-text text-center !font-poppins leading-[50px] !text-transparent md:w-full"
        >
            {item?.text}
        </Heading>)}
    </Carousel>
    );
}
 
export default HeroTitle;